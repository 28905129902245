export const sidenavData = [
  {
    title: "Home",
    icon: "home",
    path: "/home",
  },
  {
    title: "Developer Portal",
    icon: "code",
    path: "/developerPortal",
  },
  {
    title: "Payout",
    icon: "onBoarding",
    path: "",
    children: [
      {
        title: "Dashboard",
        icon: "dashboard",
        path: "/",
      },
      // {
      //   title: "Api Docs",
      //   icon: "file",
      //   path: "/file",
      // },
      {
        title: "Report",
        icon: "report",
        path: "/report",
      },
    ],
  },
  {
    title: "On-boarding",
    icon: "onBoarding",
    path: "/onBoarding",
  },
  {
    title: "Pay-in",
    icon: "onBoarding",
    path: "/payIn",
  },
];
