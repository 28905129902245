import { toast } from "react-hot-toast";
import closeIcon from "../assets/images/closeGrey.svg";
import redError from "../assets/images/redError.svg";
import blueError from "../assets/images/blueError.svg";
export const SingleErrorMessage = ({
  data,
  bColor,
  error = false,
  onClose,
}) => {
  const handleDismiss = () => {
    toast.remove();
    // onClose();
  };

  return (
    <div>
      <div className="errorLogin" style={{ border: `1px solid ${bColor}` }}>
        <div className="d-flex justify-content-between">
          <div>
            <img src={error ? redError : blueError} />
          </div>
          <div>
            <img
              src={closeIcon}
              alt="Close"
              style={{
                cursor: "pointer",
                marginLeft: "auto",
              }}
              onClick={handleDismiss}
            />
          </div>
        </div>
        <div>
          <div className="contentError">{data}</div>
          <div onClick={handleDismiss}>Dismiss</div>
        </div>
      </div>
    </div>
  );
};
