import Button from "@mui/material/Button";
export const ModalHeader = ({
  image,
  title,
  subTitle,
  isButton = false,
  onClickDownload,
}) => {
  const downloadCsv = () => {
    if (isButton) {
      onClickDownload();
    }
  };
  return (
    <>
      <div className="swich-box">
        <img src={image} alt="Switch" />
      </div>
      <div className="payout_head-text">
        <div className="payout_head-ttl">{title}</div>
        <div className="payout_head-desc">
          {subTitle}{" "}
          {isButton && <Button onClick={downloadCsv}>downLoad Sample</Button>}
        </div>
      </div>
    </>
  );
};
