import { Buffer } from "buffer";
import Excel from "exceljs";
import { saveAs } from "file-saver";

export const base64 = (data) => {
  const buffer = Buffer.from(JSON.stringify(data), "utf-8");
  return buffer.toString("base64");
};

export const formatCurrentDate = (currentDate) => {
  // Extract the year, month, and day
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  // Combine year, month, and day into the desired format "YYYY-MM-DD"
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export const formatDateTime = (isoDateTimeString) => {
  //
  const date = new Date(isoDateTimeString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear() % 100;
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Format the date and time according to the desired format
  const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}`;

  return formattedDateTime;
};
export const formatDateTable = (dateString) => {
  const date = new Date(dateString);

  // Array of day names
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Array of month names
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get day, month, and time components
  const dayOfWeek = days[date.getDay()];
  const day = date.getDate();
  const month = months[date.getMonth()];
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Convert hours to 12-hour format and determine AM/PM
  const period = hours < 12 ? "AM" : "PM";
  const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format

  // Add leading zero to minutes if needed
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

  // Construct formatted date string
  const formattedDate = `${dayOfWeek} ${day} ${month}, ${formattedHours}:${formattedMinutes} ${period}`;

  return formattedDate;
};

export const formatCurrentDateSlash = (currentDate) => {
  // Extract the year, month, and day
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  // Combine year, month, and day into the desired format "YYYY-MM-DD"
  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
};

export const validatePayment = (paymentData) => {
  const {
    beneficiary_type,
    payment_mode,
    account_number,
    ifsc,
    upi_handle,
    amount,
    beneficiary_name,
  } = paymentData;
  const errors = {};

  if (!beneficiary_type) {
    errors.beneficiary_type = "UPI and account number";
  } else if (
    beneficiary_type !== "upi" &&
    beneficiary_type !== "bank_account"
  ) {
    errors.beneficiary_type = " UPI and account number";
  }

  if (!amount || parseFloat(amount) <= 0) {
    errors.amount = "Amount is required and must be greater than 0";
  }

  if (beneficiary_type === "upi") {
    if (!upi_handle) {
      errors.upi_handle = "UPI handle is required for UPI payment";
    } else {
      if (beneficiary_type === "upi" && upi_handle) {
        const emailRegex = /^[a-zA-Z0-9.-]{2,256}@[a-zA-Z]{2,64}$/;
        if (!emailRegex.test(upi_handle)) {
          errors.upi_handle = "Invalid UPI ID format";
        }
      }
    }

    if (payment_mode !== "UPI") {
      errors.payment_mode = "Payment Should be UPI only For UPI payment";
    }

    // Check for beneficiary name
    if (!beneficiary_name || beneficiary_name.trim() === "") {
      errors.beneficiary_name = "Beneficiary name is required for UPI payment";
    }
  } else if (beneficiary_type === "bank_account") {
    // Check for account number
    if (!account_number) {
      errors.account_number =
        "Account number is required for bank account payment";
    } else {
      // Simple validation for illustration purposes
      //   const accountNumberRegex = /^\d{9,18}$/; // Assuming account number length between 9 and 18 digits
      //   if (!accountNumberRegex.test(account_number)) {
      //     errors.account_number = "Invalid bank account number format";
      // }
    }
    // Check for IFSC code
    if (!ifsc) {
      errors.ifsc = "IFSC code is required for account number payment";
    } else {
      // Use the regular expression to validate IFSC code
      const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
      if (!ifscRegex.test(ifsc)) {
        errors.ifsc = "Invalid IFSC code format";
      }
    }
    if (["NEFT", "RTGS", "IMPS"].indexOf(payment_mode) === -1) {
      errors.payment_mode = "Payment mode should be NEFT, RTGS, or IMPS only";
    }
    if (!payment_mode) {
      errors.payment_mode = "Payment mode should be NEFT, RTGS, or IMPS only";
    }

    // Check for beneficiary name
    if (!beneficiary_name || beneficiary_name.trim() === "") {
      errors.beneficiary_name =
        "Beneficiary name is required for account number payment";
    }
  }

  return errors;
};

export const validatePaymentsArray = (paymentsArray) => {
  const results = [];
  for (const paymentData of paymentsArray) {
    const validationErrors = validatePayment(paymentData);
    if (Object.keys(validationErrors).length > 0) {
      results.push({
        paymentData,
        errors: validationErrors,
      });
    }
  }
  return results;
};

export const downloadCsv = () => {
  const csvHeaders = ["Name", "Age", "Email", "Country"];

  // Define a sample data row (optional)
  const sampleDataRow = ["John Doe", "30", "john@example.com", "USA"];

  // Convert headers and sample data to CSV format
  const csvData = [
    csvHeaders.join(","), // Join headers with a comma
    sampleDataRow.join(","), // Join sample data row with a comma
  ].join("\n"); // Join each row with a newline character

  // Create a Blob from the CSV data
  const csvBlob = new Blob([csvData], { type: "text/csv" });

  // Create a URL for the Blob
  const csvUrl = URL.createObjectURL(csvBlob);
  return csvUrl;
};

export const downloadCSVTemplate = () => {
  const fixedHeader =
    "beneficiary_type,payment_mode,account_number,ifsc,upi_handle,amount,beneficiary_name";
  const blob = new Blob([fixedHeader], { type: "text/csv" });

  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "csv_template.csv");
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const downloadCSVFile = (data, fileName) => {
  const convertToCSV = (data) => {
    const headers = Object.keys(data[0]);
    const csvRows = [];

    csvRows.push(headers.join(","));

    for (const row of data) {
      const values = headers.map((header) => {
        const cell = row[header];
        return `"${cell}"`;
      });
      csvRows.push(values.join(","));
    }

    return csvRows.join("\n");
  };

  const csvData = convertToCSV(data);

  const csvBlob = new Blob([csvData], { type: "text/csv" });

  const downloadLink = document.createElement("a");
  downloadLink.href = URL.createObjectURL(csvBlob);
  downloadLink.download = fileName || "download.csv";

  document.body.appendChild(downloadLink);
  downloadLink.click();

  document.body.removeChild(downloadLink);
};

export const createTemplate = async () => {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet("Sheet1");

  const dropdownOptions = {
    A1: ["Option 1", "Option 2", "Option 3"],
    B2: ["Option A", "Option B", "Option C"],
    C3: ["Red", "Green", "Blue"],
    // Add more cells and dropdown options as needed
  };

  // Set up the data validation and cell styling for each cell
  Object.keys(dropdownOptions).forEach((cellAddress) => {
    const options = dropdownOptions[cellAddress];
    const validation = {
      type: "list",
      allowBlank: true,
      formula1: `"${options.join(",")}"`,
    };
    const cell = worksheet.getCell(cellAddress);
    cell.dataValidation = validation;
    // Set cell font color and background color
    cell.font = { color: { argb: "000000" } }; // Black font color
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFFFFF" },
    }; // White background color
  });

  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), "template.xlsx");
};
