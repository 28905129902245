import { useState, useEffect } from "react";
import "../styles/payout.css";
import "../styles/style.css";
import {
  getPayout,
  getVirtualAccountNumber,
  getDetailById,
  getTodayTransaction,
  exportPayoutData,
} from "../action/payoutApi";
import {
  base64,
  formatCurrentDate,
  formatCurrentDateSlash,
  downloadCSVFile,
} from "../commonFunction/utils";
import { PayoutModelData, NewPayoutModelData } from "../model/tableModel";
import Pagination from "@mui/material/Pagination";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { AddPayout } from "./addPayout";
import { Backdrop } from "../pages/backdrop";
import { ShowRowData } from "../pages/dialogForShowRowData";
import moment from "moment";
import "../styles/table.css";
import circleInfo from "../assets/images/info-circle.svg";
import helpInfo from "../assets/images/need_help.svg";
import arrowDown from "../assets/images/arrow-down copy.svg";
import arrowup from "../assets/images/arrow-up.svg";
import transArrow from "../assets/images/trans_arrow.svg";
import search from "../assets/images/search-lg.svg";
import ExportIcom from "../assets/images/exp_btn.svg";
import cherDown from "../assets/images/chevron-down.svg";
import { ExportBulkPayout } from "./uploadBulkPayout";
import { Beneficiary } from "../pages/addBenifiary";
import { Loader } from "./loader";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

export function Payout() {
  const [limits, setLimits] = useState(10);
  const [payoutData, setPayoutData] = useState();
  const [todayTransactionData, setTodayTransactionData] = useState(null);
  const [rowTableData, setRowTableData] = useState([]);
  const [virtualAccNumber, setVirtualAccount] = useState([]);
  const [selectValue, setSelectValue] = useState("Transaction ID");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [isSearch, setIsSearch] = useState(true);
  const [minDate, setMinDate] = useState("");
  const [paginationValue, setPaginationValue] = useState(1);
  const [openAddPayout, setOpenPayout] = useState(false);
  const [openBulkPay, setopenBulkPay] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [isTotalBalance, setIsTotalBalance] = useState(false);
  const [isUseBalance, setIsUseBalance] = useState(false);
  const [openBeneficiary, setOpenBeneficiary] = useState(false);
  const [initiateProcessData, setInitiateProcessData] = useState(null);
  const [prevSearchValue, setPrevSearchValue] = useState("");

  const handleChange = (event) => {
    setSelectValue(event.name);
    setIsSearch(false);
    setSearchValue("");
  };

  const handlePopupClose = (value) => {
    if (value === "Success") {
      getPayoutData();
      getVirtualNumber();
      getTodayData();
    }
    setInitiateProcessData(null);
    setOpenPayout(false);
  };
  const handleOpen = () => {
    setOpenPayout(true);
  };

  const openBulkPayout = () => {
    setopenBulkPay(true);
  };
  const handlePopupBulkPay = (data) => {
    if (data === "Success") {
      getPayoutData();
      getVirtualNumber();
      getTodayData();
    }
    setopenBulkPay(false);
  };

  const getTodayData = () => {
    setIsUseBalance(true);
    getTodayTransaction()
      .then((res) => {
        if (res.status === 1) {
          setTodayTransactionData(res.data);
          setIsUseBalance(false);
        } else {
          setIsUseBalance(false);
        }
      })
      .catch((err) => {
        setIsUseBalance(false);
        console.log("error", err);
      });
  };

  useEffect(() => {
    setPaginationValue(1);
    getPayoutData();
  }, [searchValue, startDate, endDate]);

  useEffect(() => {
    getPayoutData();
  }, [paginationValue]);

  useEffect(() => {
    getVirtualNumber();
    getTodayData();
  }, []);

  const getPayoutData = () => {
    // setIsLoadingTable(true);
    var d = new Date();
    const body = {};

    if (startDate && endDate) {
      body.created_at_start = moment(startDate).format("YYYY-MM-DD");
      body.created_at_end = moment(endDate).format("YYYY-MM-DD");
    } else {
      body.created_at_start = formatCurrentDate(d);
      body.created_at_end = formatCurrentDate(d);
    }
    if (getSelectedValue(selectValue)) {
      body[getSelectedValue(selectValue)] = searchValue;
    }
    // body.current = paginationValue;
    // body.pageSize = limits;

    getPayout(limits, paginationValue, {
      data: base64(body),
    }).then((res) => {
      if (res.message === "Success") {
        const rowData = res.data.result.map((ele) => new PayoutModelData(ele));
        // const rowData = res.data.results.map(
        //   (ele) => new NewPayoutModelData(ele)
        // );

        setRowTableData(rowData);
        setPayoutData(res.data);
        // setIsLoadingTable(false);
      } else {
        // setIsLoadingTable(false);
      }
    });
  };

  const exportData = () => {
    const payload = {
      created_at_start: moment(startDate).format("YYYY-MM-DD"),
      created_at_end: moment(endDate).format("YYYY-MM-DD"),
    };
    if (payload.created_at_end && payload.created_at_start) {
      exportPayoutData({ data: base64(payload) })
        .then((response) => {
          if (response.status === 1) {
            const exportData = response.data.map((ele) => {
              return {
                transaction_request_number: ele.unique_request_number,
                amount: ele.amount,
                beneficiary_bank_name: ele.beneficiary_bank_name,
                beneficiary_account_number: ele.beneficiary_account_number,
                transfer_date: ele.transfer_date,
                beneficiary_name: ele.beneficiary_account_name,
                beneficiary_account_ifsc: ele.beneficiary_account_ifsc,
                beneficiary_upi_handle: ele.beneficiary_upi_handle,
                payment_mode: ele.payment_mode,
                source_virtual_account: ele.source_virtual_account,
                status: ele.status,
                failure_reason: ele.failure_reason,
                unique_transaction_reference: ele.unique_transaction_reference,
              };
            });

            downloadCSVFile(exportData, "payout");
          } else {
            toast.error(response.message);
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };

  const getVirtualNumber = () => {
    setIsTotalBalance(true);
    getVirtualAccountNumber().then((response) => {
      if (response.message === "Success") {
        const value = response.data?.filter((ele) => ele.is_active == true);

        setVirtualAccount(value[0]);
        setIsTotalBalance(false);
      } else {
        setIsTotalBalance(false);
      }
    });
  };

  function roundToFourDecimalPlaces(number) {
    const NewNumber = Number(number);
    if (NewNumber === null || NewNumber === undefined) {
      return 0;
    }

    return parseFloat(NewNumber.toFixed(4));
  }

  const handleRowChange = (singleValue) => {
    setSelectedData(singleValue.singleData);
    setOpenDialog(true);
  };

  const handleOnClose = () => {
    setOpenDialog(false);
  };

  const statusColorMapping = {
    Accepted: "success",
    failure: "failed",
    Failed: "failed",
    Processing: "processing",
    Pending: "pending",
    Rejected: "rejected",
    Reversed: "reverse",
    Success: "success",
  };
  const getStatusClassName = (status) => {
    return statusColorMapping[status] || "";
  };

  const currentDate = moment();

  const onClickPayout = (data) => {
    if (data === "singlePayout") {
      handleOpen();
    } else {
      openBulkPayout();
    }
  };

  const paginationChange = (event, newPage) => {
    setPaginationValue(newPage);
  };

  const handleExportData = () => {
    exportData();
  };

  // const getPaginationCount = (data) => {
  //   const pagination = data?.pagination;
  //   if (pagination.hasNext) {
  //     return limits + 1;
  //   } else {
  //     return limits;
  //   }
  // };
  const [toggleSelect, setToggleSelect] = useState(false);
  const [toggleAddPayout, setToggleAddPayout] = useState(false);
  const handleSelect = () => {
    setToggleSelect(!toggleSelect);
  };
  const handleAddPayout = () => {
    setToggleAddPayout(!toggleAddPayout);
  };

  //  beneficiary open close

  const handleCloseBeneficiary = (data) => {
    if (data.message === "INITIATEPROCESS") {
      setInitiateProcessData(data.value);
      handleOpen();
    }
    if (data.message === "") {
      handleOpen();
    }
    setOpenBeneficiary(false);
  };
  const handleOpenBeneficiary = () => {
    setOpenBeneficiary(true);
    setInitiateProcessData(null);
  };

  const onCloseOpenPayout = () => {
    handleOpen();
  };

  const getSelectedValue = (value) => {
    const selectedElement = selectDataValue.find((ele) => ele.name === value);
    return selectedElement ? selectedElement.value : "";
  };
  const selectDataValue = [
    { name: "Transaction ID", value: "transactionId" },
    { name: "Account Number", value: "beneficiary_account_number" },
    { name: "Beneficiary Name", value: "beneficiary_account_name" },
  ];
  const [sortDirection, setSortDirection] = useState("asc");
  const toggleSortDirection = () => {
    setSortDirection((prevDirection) =>
      prevDirection === "asc" ? "desc" : "asc"
    );
  };
  const handleSortByAmount = (pagination) => {
    const sortedData = [...rowTableData];
    sortedData.sort((a, b) => {
      const amountA = parseFloat(a.amount);
      const amountB = parseFloat(b.amount);
      if (sortDirection === "asc") {
        return amountA - amountB;
      } else {
        return amountB - amountA;
      }
    });
    setRowTableData(sortedData);
    toggleSortDirection();
  };
  const arrowIcon = sortDirection === "asc" ? arrowup : arrowDown;

  const [sortDateDirection, setSortDateDirection] = useState("asc");
  const toggleSortDateDirection = () => {
    setSortDateDirection((prevDirection) =>
      prevDirection === "asc" ? "desc" : "asc"
    );
  };
  const handleSortDate = () => {
    const sortedData = [...rowTableData];
    sortedData.sort((a, b) => {
      const dateA = new Date(a.singleData.createdAt);
      const dateB = new Date(b.singleData.createdAt);

      if (sortDateDirection === "asc") {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });
    setRowTableData(sortedData);
    toggleSortDateDirection();
  };
  const arrowIconDate = sortDateDirection === "asc" ? arrowup : arrowDown;

  return (
    <>
      <div className="dashboard_head">
        <div className="dashboard_head-left">
          <div className="dashboard_title">Payout Dashboard</div>
          <div className="dashboard_desc">
            Your payouts summary and activity.
          </div>
        </div>
        <div className="dashboard_head-right">
          <Link
            className="dashboard_need"
            onClick={(e) => {
              window.location.href = "mailto:support@seccure.biz";
              e.preventDefault();
            }}
          >
            <img className="submenu_icon" src={helpInfo} alt="" />
            Need Help?
          </Link>
        </div>
      </div>
      <div className="dashboard_cards">
        <div className="row">
          <div className="col-lg-6">
            <div className="dashboard_card">
              <div className="dashboad_card-head">
                <div className="dashboard_card-left">Today’s Transactions</div>
                <div className="dashboard_card-right">
                  <img src={circleInfo} alt="" />
                </div>
              </div>
              {isUseBalance ? (
                <Loader />
              ) : (
                <div className="dashboard_card-ttl blue">
                  &#8377;&nbsp;
                  {todayTransactionData
                    ? todayTransactionData?.totalAmount
                    : "0"}
                  /-
                </div>
              )}
            </div>
          </div>
          {/* <div className="col-lg-4">
              <div className="dashboard_card">
                <div className="dashboad_card-head">
                  <div className="dashboard_card-left">
                    Total Transactions (in Rs.)
                  </div>
                  <div className="dashboard_card-right">
                    <img src="/assets/images/info-circle.svg" alt="" />
                  </div>
                </div>
                <div className="dashboard_card-ttl">8,10,280/-</div>
              </div>
            </div> */}
          <div className="col-lg-6">
            <div className="dashboard_card">
              <div className="dashboad_card-head">
                <div className="dashboard_card-left">Account Balance</div>
                <div className="dashboard_card-right">
                  <img src={circleInfo} alt="" />
                </div>
              </div>
              {isTotalBalance ? (
                <Loader />
              ) : (
                <div className="dashboard_card-ttl blue">
                  {" "}
                  &#8377;&nbsp;
                  {roundToFourDecimalPlaces(virtualAccNumber.balance) ?? 0}/-
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="payout_data-content">
        <div className="payout_data-head">
          <div className="payout_data-head-top">
            <div className="payout_data-left">
              <div className="payout_data_ttl">
                Your Daily Payouts
                <div className="hits">
                  {" "}
                  {todayTransactionData?.todayTotalQuantity} Hits
                </div>
              </div>
              <div className="payout_data-desc">
                Keep track of your daily payouts.
              </div>
            </div>
            <div className="payout_data-right">
              <div
                className={`add_payout ${toggleAddPayout ? "active" : ""}`}
                onClick={handleAddPayout}
              >
                Add Payout
                <img src={cherDown} alt="" className="payout_down" />
                <div className="addPayout_drop">
                  <div
                    className="add_payout-drop_item"
                    onClick={() => {
                      onClickPayout("singlePayout");
                    }}
                  >
                    Single Payout
                  </div>
                  <div
                    className="add_payout-drop_item"
                    onClick={() => {
                      onClickPayout("bulkPayout");
                    }}
                  >
                    Bulk Payout
                  </div>
                </div>
                <>
                  {" "}
                  <AddPayout
                    open={openAddPayout}
                    onClose={handlePopupClose}
                    openAddBeneficiary={handleOpenBeneficiary}
                    initiateData={initiateProcessData}
                    virtualBalance={roundToFourDecimalPlaces(
                      virtualAccNumber.balance
                    )}
                  />
                  <Beneficiary
                    open={openBeneficiary}
                    onClose={handleCloseBeneficiary}
                    // openPayout={onCloseOpenPayout}
                  />
                </>
              </div>
            </div>
          </div>
          <div className="payout_data-head-bottom">
            <div className="payout_data-head-bottom_left">
              <div
                className={`transaction_id ${toggleSelect ? "active" : ""}`}
                onClick={handleSelect}
              >
                {selectValue}
                <img src={transArrow} alt="" className="transArrow" />
                <div className="transDrop">
                  {selectDataValue.map((ele) => (
                    <div
                      key={ele.value}
                      className="transDrop-item"
                      onClick={() => {
                        handleChange(ele);
                      }}
                    >
                      {ele.name}
                    </div>
                  ))}
                  {/* <div className="transDrop-item" onClick={() => {}}>
                      Transaction ID
                    </div>
                    <div className="transDrop-item">Account Number</div>
                    <div className="transDrop-item">Beneficiary Name</div> */}
                </div>
              </div>
              <div className="searchbox">
                <img src={search} alt="" className="search_icon" />
                <input
                  type="text"
                  className="seachInput"
                  placeholder="Search"
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="payout_data-head-bottom_right">
              <div className="custominput">
                <DateRangePicker
                  startDate={startDate}
                  startDateId="start_date_id"
                  endDate={endDate}
                  endDateId="end_date_id"
                  small={true}
                  readOnly={true}
                  minDate={moment().subtract(1, "year")}
                  maxDate={moment()}
                  minimumNights={0}
                  displayFormat="MMMM D, YYYY"
                  onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                  }}
                  isOutsideRange={(date) =>
                    date.isBefore(minDate, "day") ||
                    date.isAfter(currentDate, "day")
                  }
                  focusedInput={focusedInput}
                  onFocusChange={(focusedInput) =>
                    setFocusedInput(focusedInput)
                  }
                />
              </div>
              <div
                className={`export_btn ${
                  !endDate || !startDate ? "" : "active"
                }`}
                // data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
                onClick={handleExportData}
              >
                <img src={ExportIcom} alt="" className="expIcon" />
                Export
              </div>
            </div>
          </div>
        </div>
        <div className="payout_table-box">
          <table className="payout_table">
            <tbody>
              <tr className="tableHead">
                <th className="thHeader">
                  <div className="payoutHead" onClick={handleSortDate}>
                    Created on{" "}
                    <img
                      src={arrowIconDate}
                      alt=""
                      className="payoutHead_icon"
                    />
                  </div>
                </th>
                <th className="thHeader">
                  <div
                    className="payoutHead"
                    onClick={() => {
                      handleSortByAmount("rowClick");
                    }}
                  >
                    Amount (in Rs.)
                    <img
                      src={arrowIcon}
                      alt=""
                      className="payoutHead_icCreated on on"
                    />
                  </div>
                </th>
                <th className="thHeader">
                  <div className="payoutHead">
                    Status
                    {/* <img src={arrowup} alt="" className="payoutHead_icon" /> */}
                  </div>
                </th>
                <th className="thHeader">
                  <div className="payoutHead">Beneficiary Name</div>
                </th>
                <th className="thHeader">
                  <div className="payoutHead">Created By </div>
                </th>
                <th className="thHeader">
                  <div className="payoutHead">UTR </div>
                </th>
                <th className="thHeader">
                  <div className="payoutHead">Payout </div>
                </th>
              </tr>
              {rowTableData.length > 0 ? (
                rowTableData.map((ele) => (
                  <tr
                    key={ele.urn + ele.createdOn}
                    onClick={() => {
                      handleRowChange(ele);
                    }}
                    className="tableBody_"
                  >
                    <td>{ele.createdOn}</td>
                    <td className="table_amount">{ele.amount}</td>
                    <td className="Status">
                      <div className={`${getStatusClassName(ele.status)}`}>
                        {ele.status}
                      </div>
                    </td>
                    <td>{ele.beneficiary}</td>
                    <td>{ele.createdBy}</td>
                    <td>{ele.utr}</td>
                    <td>{ele.payout}</td>
                  </tr>
                ))
              ) : (
                <>
                  <tr>
                    <td colSpan="6" className="text-center">
                      No data available
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
          <div
            className="d-flex justify-content-center align-items-center p-2"
            style={{ borderTop: "1px solid #e0e0e0" }}
          >
            {rowTableData.length > 0 && (
              <Pagination
                count={Math.ceil(Number(payoutData?.totalCount) / 10) || 0}
                page={paginationValue}
                onChange={paginationChange}
                color="primary"
              />
            )}
          </div>
          <Backdrop openDialog={openDialog} handleClose={handleOnClose} />
          <ShowRowData
            openDialog={openDialog}
            handleClose={handleOnClose}
            data={selectedData}
          />
          <ExportBulkPayout
            open={openBulkPay}
            onClose={handlePopupBulkPay}
            virtualBalance={roundToFourDecimalPlaces(virtualAccNumber.balance)}
          />
        </div>
      </div>
    </>
  );
}
