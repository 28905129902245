import { Suspense, Fragment } from "react";
import { Outlet } from "react-router-dom";
import { SideNav } from "./sideNav";
import "../styles/payout.css";
import "../styles/style.css";

export function DashboardLayout() {
  return (
    <div className="payout_page">
      <div
        className="sidemenu-container"
        style={{ position: "relative", display: "inline-block" }}
      >
        <SideNav />
      </div>
      <div className="payout_dashboard">
        <div className="payout_content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
