import { ReusableDialog } from "../common/dialog";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addBeneficiaryByUpi } from "../action/beneficiary";
import { base64 } from "../commonFunction/utils";
import { UpiSuccess } from "../pages/UPISuccess";
import { toast } from "react-hot-toast";
import lightning from "../assets/images/lightning-01.svg";
import bank from "../assets/images/bank.svg";
import "../styles/addPayout.css";
import payoutIcon from "../assets/images/addBeneficiaryPlus.png";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import { ModalHeader } from "./modalHeader";
import closeIcon from "../assets/images/x-close.svg";
import chevronRight from "../assets/images/chevron-right.svg";
import { useNavigate } from "react-router-dom";
import crossIcon from "../assets/images/closeGrey.svg";
import { SingleErrorMessage } from "./singleErrorMessage";
import blueTickIcon from "../assets/images/blueTickIcon.svg";
import maskIcon from "../assets/images/maskIcon.svg";

export const Beneficiary = ({ open, onClose, openPayout }) => {
  const handleClose = (data) => {
    onClose(data);
    bankAcountForm.resetForm();
    UpiForm.resetForm();
    // setPopAfterSucess(true);
  };

  // OPEN-CLOSE ADD

  // radio button
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState("bank_account");
  const [popAfterSucess, setPopAfterSucess] = useState(false);
  const [resultData, setResultData] = useState("");
  const [status, setStatus] = useState("");
  const handleClosePopUp = () => {
    setPopAfterSucess(false);
    openPayout()
  };
  const handleChange = (event) => {
    setSelectedValue(event);
  };
  const isActive = (value) => {
    return selectedValue === value;
  };
  const navigate = useNavigate();
  //--------------- validation on add bank account beneficiary---
  const [isMasked, setIsMasked] = useState(true);

  // Function to toggle masking/unmasking
  const toggleMask = () => {
    setIsMasked(!isMasked);
  };

  const validationSchema = Yup.object().shape({
    accountNumber: Yup.string()
      .required("Account number is required")
      .matches(/^\d+$/, "Account number must be numeric")
      .min(10, "Account number must be at least 10 digits")
      .max(16, "Account number must be at most 16 digits"),
    reEnterAccountNumber: Yup.string()
      .required("Please re-enter the account number")
      .oneOf([Yup.ref("accountNumber")], "Account numbers must match"),
    ifsc: Yup.string()
      .required("IFSC code is required")
      .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC code"),
    beneficiary: Yup.string()
      .required("Beneficiary name is required")
      .min(3, "Beneficiary name must be at least 3 characters")
      .max(50, "Beneficiary name must be at most 50 characters")
      .matches(/^[a-zA-Z ]+$/, "Beneficiary name must contain only alphabets"),
  });

  // Create the Formik instance
  const bankAcountForm = useFormik({
    initialValues: {
      accountNumber: "",
      reEnterAccountNumber: "",
      ifsc: "",
      beneficiary: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {},
  });

  // =------------------end --------------------------

  // -------------------------------validation on UPI----------------------

  const validationSchemaUPI = Yup.object().shape({
    upiId: Yup.string()
      .required("UPI ID is required")
      .matches(
        /^[a-zA-Z0-9.-]{2,256}@[a-zA-Z]{2,64}$/,
        "Invalid UPI ID format"
      ),
    beneficiary: Yup.string()
      .required("Beneficiary name is required")
      .min(3, "Beneficiary name must be at least 3 characters")
      .max(50, "Beneficiary name must be at most 50 characters")
      .matches(/^[a-zA-Z ]+$/, "Beneficiary name must contain only alphabets"),
  });

  const UpiForm = useFormik({
    initialValues: {
      upiId: "",
      beneficiary: "",
    },
    validationSchema: validationSchemaUPI,
    onSubmit: (values) => {},
  });
  const handleCloseToaster = () => {
    toast.dismiss();
  };
  const HandleUpi = () => {
    setIsLoading(true);
    const payload = {
      beneficiary_type: selectedValue,
      beneficiary_name: UpiForm.values.beneficiary,
      upi_handle: UpiForm.values.upiId,
    };
    addBeneficiaryByUpi({ data: base64(payload) }).then((result) => {
      if (result.message === "Success") {
        setResultData(result.data);
        setPopAfterSucess(true);
        setStatus("UPI");
        handleClose({ message: "Success", data: {} });
        setIsLoading(false);
      }
      if (result.status === 0) {
        toast.custom(
          <SingleErrorMessage
            data={result.message}
            error={true}
            bColor={"#D92D20"}
            onClose={handleCloseToaster}
          />
        );
        setIsLoading(false);
      }
    });
  };
  const HandleBank = () => {
    setIsLoading(true);
    const payload = {
      beneficiary_type: selectedValue,
      beneficiary_name: bankAcountForm.values.beneficiary,
      account_number: bankAcountForm.values.accountNumber,
      ifsc: bankAcountForm.values.ifsc,
    };
    addBeneficiaryByUpi({ data: base64(payload) }).then((result) => {
      if (result.message === "Success") {
        setPopAfterSucess(true);
        setResultData(result.data);
        setStatus("BANK");
        handleClose({ message: "Success", data: {} });
        setIsLoading(false);
      }
      if (result.status === 0) {
        toast.custom(
          <SingleErrorMessage
            data={result.message}
            error={true}
            bColor={"#D92D20"}
            onClose={handleCloseToaster}
          />
        );
        // toast.error(result.message);
        setIsLoading(false);
      }
    });
  };
  const handleClickBackDash = (value) => {
    if (value === "CLOSE") {
      handleClosePopUp();
    } else if (value === "UPI") {
      handleClose({
        message: "INITIATEPROCESS",
        value: { type: "UPI", data: resultData },
      });
      handleClosePopUp();
    } else {
      handleClose({
        message: "INITIATEPROCESS",
        value: { type: "BANK", data: resultData },
      });
      handleClosePopUp();
    }
  };

  // -----------------------------end Upi ---------------------------------

  const [isHidden, setIsHidden] = useState(false);
  const [isCheckBox, setCheckBox] = useState(false);

  useEffect(() => {
    const storedValue = localStorage.getItem("hideContent2");
    setIsHidden(storedValue === "true");
  }, []);

  const handleCheckboxChange = (event) => {
    setCheckBox(event.target.checked);
  };

  const closeCrossIcon = () => {
    setIsHidden(true);
  };

  const dismissCross = () => {
    setIsHidden(true);
    if (isCheckBox) {
      localStorage.setItem("hideContent2", true);
    }
  };

  const dialogStyles = {
    width: "50%",
    height: "auto",
    maxWidth: "none",
  };
  return (
    <div>
      {popAfterSucess && (
        <ReusableDialog
          open={popAfterSucess}
          onClose={handleClosePopUp}
          body={
            <UpiSuccess
              data={resultData}
              status={status}
              handleClickBack={handleClickBackDash}
            />
          }
        />
      )}
      <ReusableDialog
        open={open}
        onClose={() => {
          handleClose({ message: "", data: {} });
          bankAcountForm.resetForm();
          UpiForm.resetForm();
          // setIsHidden(false);
        }}
        dialogStyles={dialogStyles}
        title={
          <div className="create_payout-head">
            <div className="create_payout-head_left">
              <ModalHeader
                image={payoutIcon}
                title={"Add Beneficiary"}
                subTitle={"manage Your Beneficiary at one place"}
              />
            </div>
          </div>
        }
        body={
          <>
            <div className="payment_mode">
              <div className="payment_title">
                Account Type<span>*</span>
              </div>
              <div className="payment_cards">
                <div
                  className={`payment_card ${
                    selectedValue === "bank_account" ? "active" : ""
                  }`}
                  onClick={() => {
                    handleChange("bank_account");
                  }}
                >
                  <div className="payment_cardImg">
                    <img src={lightning} alt="" className="payCardImg" />
                  </div>
                  <div className="payment_card_text">BANK A/C</div>
                </div>
                <div
                  className={`payment_card ${
                    selectedValue === "upi" ? "active" : ""
                  }`}
                  onClick={() => {
                    handleChange("upi");
                  }}
                >
                  <div className="payment_cardImg">
                    <img src={bank} alt="" className="payCardImg" />
                  </div>
                  <div className="payment_card_text">UPI ID</div>
                </div>
              </div>
              <div className="payment_detail_ttl"></div>
              {selectedValue === "upi" ? (
                <>
                  <form
                    action=""
                    className="payment_form"
                    onSubmit={UpiForm.handleSubmit}
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="input-box">
                          <div className="input-label">
                            Beneficiary Name<span>*</span>
                          </div>
                          <div className="input_field">
                            <input
                              type="text"
                              className={`input ${
                                UpiForm.errors.beneficiary &&
                                UpiForm.touched.beneficiary
                                  ? "input-error"
                                  : ""
                              }`}
                              placeholder="Enter your beneficiary name"
                              id="beneficiary"
                              name="beneficiary"
                              value={UpiForm.values.beneficiary}
                              onChange={UpiForm.handleChange}
                              onBlur={UpiForm.handleBlur}
                            />
                          </div>
                          {UpiForm.touched.beneficiary &&
                            UpiForm.errors.beneficiary && (
                              <div className="danger">
                                {UpiForm.errors.beneficiary}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-box">
                          <div className="input-label">
                            UPI Id<span>*</span>
                          </div>
                          <div className="input_field">
                            <input
                              type="text"
                              className={`input ${
                                UpiForm.errors.upiId && UpiForm.touched.upiId
                                  ? "input-error"
                                  : ""
                              }`}
                              placeholder="Enter your UPI ID"
                              id="upiId"
                              name="upiId"
                              value={UpiForm.values.upiId}
                              onChange={UpiForm.handleChange}
                              onBlur={UpiForm.handleBlur}
                            />
                          </div>
                          {UpiForm.touched.upiId && UpiForm.errors.upiId && (
                            <div className="danger">{UpiForm.errors.upiId}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="benyf_btn">
                    <div
                      className="beny_left-btn"
                      onClick={() => handleClose({ message: "", data: {} })}
                    >
                      <img src={closeIcon} alt="" className="close" />
                      Close
                    </div>
                    <div
                      className={`beny_right-btn newClass ${
                        isLoading || !UpiForm.isValid || !UpiForm.dirty
                          ? ""
                          : "active"
                      }`}
                      onClick={HandleUpi}
                    >
                      Add Beneficiary{" "}
                      {isLoading ? (
                        <CircularProgress
                          style={{ height: "30px", width: "30px" }}
                        />
                      ) : (
                        ""
                      )}
                      {/* <img src={chevronRight} alt="" className="bany_next" /> */}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <form
                    action=""
                    className="payment_form"
                    onSubmit={bankAcountForm.handleSubmit}
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="input-box">
                          <div className="input-label">
                            A/C Number<span>*</span>
                          </div>
                          <div className="input_field">
                            <input
                              type={isMasked ? "password" : "text"}
                              className={`input ${
                                bankAcountForm.errors.accountNumber &&
                                bankAcountForm.touched.accountNumber
                                  ? "input-error"
                                  : ""
                              }`}
                              placeholder="Enter your account number"
                              id="accountNumber"
                              name="accountNumber"
                              value={bankAcountForm.values.accountNumber}
                              onChange={bankAcountForm.handleChange}
                              onBlur={bankAcountForm.handleBlur}
                            />
                            <div
                              className="tickIconInput eyeIcon"
                              onClick={toggleMask}
                            >
                              {!isMasked ? (
                                <i
                                  className="fa fa-eye iconEye"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-eye-slash iconEye"
                                  aria-hidden="true"
                                ></i>
                              )}
                            </div>
                            {/* <img
                              className="tickIconInput"
                              src={maskIcon}
                              alt=""
                              onClick={toggleMask}
                            /> */}
                          </div>
                          {bankAcountForm.errors.accountNumber &&
                            bankAcountForm.touched.accountNumber && (
                              <div className="danger">
                                {bankAcountForm.errors.accountNumber}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-box">
                          <div className="input-label">
                            Re-enter A/C Number<span>*</span>
                          </div>
                          <div className="input_field">
                            <input
                              type="text"
                              className={`input ${
                                bankAcountForm.errors.reEnterAccountNumber &&
                                bankAcountForm.touched.reEnterAccountNumber
                                  ? "input-error"
                                  : ""
                              }`}
                              placeholder="Re-enter your account number"
                              id="reEnterAccountNumber"
                              name="reEnterAccountNumber"
                              value={bankAcountForm.values.reEnterAccountNumber}
                              onChange={bankAcountForm.handleChange}
                              onBlur={bankAcountForm.handleBlur}
                            />
                            {bankAcountForm.values.reEnterAccountNumber ===
                              bankAcountForm.values.accountNumber &&
                              bankAcountForm.values.reEnterAccountNumber !==
                                "" &&
                              bankAcountForm.values.accountNumber !== "" && (
                                <img
                                  className="tickIconInput"
                                  src={blueTickIcon}
                                  alt=""
                                />
                              )}
                          </div>
                          {bankAcountForm.errors.reEnterAccountNumber &&
                            bankAcountForm.touched.reEnterAccountNumber && (
                              <div className="danger">
                                {bankAcountForm.errors.reEnterAccountNumber}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-box">
                          <div className="input-label">
                            IFSC Code<span>*</span>
                          </div>
                          <div className="input_field">
                            <input
                              type="text"
                              className={`input ${
                                bankAcountForm.errors.ifsc &&
                                bankAcountForm.touched.ifsc
                                  ? "input-error"
                                  : ""
                              }`}
                              placeholder="Enter your IFSC code"
                              id="ifsc"
                              name="ifsc"
                              value={bankAcountForm.values.ifsc.toUpperCase()}
                              onChange={bankAcountForm.handleChange}
                              onBlur={bankAcountForm.handleBlur}
                            />
                          </div>
                          {bankAcountForm.errors.ifsc &&
                            bankAcountForm.touched.ifsc && (
                              <div className="danger">
                                {bankAcountForm.errors.ifsc}
                              </div>
                            )}
                          <div className="ifsc">
                            Check your IFSC Code <a href="#">here.</a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-box">
                          <div className="input-label">
                            Beneficiary Name<span>*</span>
                          </div>
                          <div className="input_field">
                            <input
                              type="text"
                              className={`input ${
                                bankAcountForm.errors.beneficiary &&
                                bankAcountForm.touched.beneficiary
                                  ? "input-error"
                                  : ""
                              }`}
                              placeholder="Enter your beneficiary name"
                              id="beneficiary"
                              name="beneficiary"
                              value={bankAcountForm.values.beneficiary}
                              onChange={bankAcountForm.handleChange}
                              onBlur={bankAcountForm.handleBlur}
                            />
                          </div>
                          {bankAcountForm.errors.beneficiary &&
                            bankAcountForm.touched.beneficiary && (
                              <div className="danger">
                                {bankAcountForm.errors.beneficiary}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </form>
                  {!isHidden && (
                    <div className="imp_note">
                      <div className="d-flex justify-content-between">
                        <div className="note_ttl">An important note!</div>
                        <div onClick={closeCrossIcon}>
                          <img src={crossIcon} alt="" />
                        </div>
                      </div>

                      <div className="note_text">
                        Banks generally take 30 mins to 4 hrs to authenticate
                        beneficiary details. During this cooling period in the
                        bank, the funds will not be transferred resulting in
                        payment delays.
                      </div>
                      <div className="note_text">
                        Once the beneficiary is activated, the funds are
                        transferred to the specified account.
                      </div>
                      <div className="note_text">
                        Depending on the mode of payment (NEFT, RTGS, IMPS)
                        &amp; the cooling period in the bank for receiver detail
                        activation, the entire process of payouts can take up to
                        2 – 24 hrs.
                      </div>
                      <div className="note_check">
                        <input
                          type="checkbox"
                          id="note_checkbox"
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="note_checkbox">
                          Don’t show me again
                        </label>
                        <button className="btn_dismiss" onClick={dismissCross}>
                          Dismiss
                        </button>
                      </div>
                      <div className="note_close-btn">
                        <img src="/assets/images/x-close.svg" alt="" />
                      </div>
                    </div>
                  )}

                  <div className="benyf_btn">
                    <div
                      className="beny_left-btn "
                      onClick={() => handleClose({ message: "", data: {} })}
                    >
                      <img src={closeIcon} alt="" className="close" />
                      Close
                    </div>
                    <div
                      className={`beny_right-btn newClass ${
                        isLoading ||
                        !bankAcountForm.isValid ||
                        !bankAcountForm.dirty
                          ? ""
                          : "active"
                      }`}
                      onClick={HandleBank}
                    >
                      Add Beneficiary{" "}
                      {isLoading ? (
                        <CircularProgress
                          style={{ height: "30px", width: "30px" }}
                        />
                      ) : (
                        ""
                      )}
                      {/* <img src={chevronRight} alt="" className="bany_next" /> */}
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        }
      />
    </div>
  );
};
