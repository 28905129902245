import React, { useEffect } from "react"
import axis from "../assets/images/Axis.jpg";
import card from "../assets/images/Card.jpg";
import cardShadow from "../assets/images/Card-shadow.svg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export function SlickSlider() {
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        dots: true,
        arrows: true,
        speed:500,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
      };

  return (
    <Slider  {...settings} className="insights-slider">
      <div className="login_left-slider-items">
        <div className="login_left-item">
          <div className="login_left-img-box">
            <div className="login_left-img-box-content">
              <img src={axis} alt="" className="login_left-image" />
            </div>
            <div className="empty_box-b1" />
            <div className="empty_box-b2" />
            <div className="empty_box-s" />
            <div className="shadow_card1">
              <img src={card} alt="" className="shadow-cardImg" />
            </div>
            <div className="shadow_card2">
              <img src={cardShadow} alt="" className="shadow-cardImg" />
            </div>
          </div>
          <div className="login_left-text_content">
            <div className="login_left-title">Introducing AutoPayouts 2.0®</div>
            <div className="login_left-text">
              Powerful, self-serve product and growth analytics to
              <br /> help you convert, engage, and retain more users.
            </div>
          </div>
        </div>
      </div>
      <div className="login_left-slider-items">
        <div className="login_left-item">
          <div className="login_left-img-box">
            <div className="login_left-img-box-content">
              <img
                src={axis}
                alt=""
                className="login_left-image"
              />
            </div>
            <div className="empty_box-b1" />
            <div className="empty_box-b2" />
            <div className="empty_box-s" />
            <div className="shadow_card1">
              <img
                src={card}
                alt=""
                className="shadow-cardImg"
              />
            </div>
            <div className="shadow_card2">
              <img
                src={cardShadow}
                alt=""
                className="shadow-cardImg"
              />
            </div>
          </div>
          <div className="login_left-text_content">
            <div className="login_left-title">Introducing AutoPayouts 2.0®</div>
            <div className="login_left-text">
              Powerful, self-serve product and growth analytics to
              <br /> help you convert, engage, and retain more users.
            </div>
          </div>
        </div>
      </div>
      <div className="login_left-slider-items">
        <div className="login_left-item">
          <div className="login_left-img-box">
            <div className="login_left-img-box-content">
              <img
                src={axis}
                alt=""
                className="login_left-image"
              />
            </div>
            <div className="empty_box-b1" />
            <div className="empty_box-b2" />
            <div className="empty_box-s" />
            <div className="shadow_card1">
              <img
                src={card}
                alt=""
                className="shadow-cardImg"
              />
            </div>
            <div className="shadow_card2">
              <img
                src={cardShadow}
                alt=""
                className="shadow-cardImg"
              />
            </div>
          </div>
          <div className="login_left-text_content">
            <div className="login_left-title">Introducing AutoPayouts 2.0®</div>
            <div className="login_left-text">
              Powerful, self-serve product and growth analytics to
              <br /> help you convert, engage, and retain more users.
            </div>
          </div>
        </div>
      </div>
      <div className="login_left-slider-items">
        <div className="login_left-item">
          <div className="login_left-img-box">
            <div className="login_left-img-box-content">
              <img
                src={axis}
                alt=""
                className="login_left-image"
              />
            </div>
            <div className="empty_box-b1" />
            <div className="empty_box-b2" />
            <div className="empty_box-s" />
            <div className="shadow_card1">
              <img
                src={card}
                alt=""
                className="shadow-cardImg"
              />
            </div>
            <div className="shadow_card2">
              <img
                src={cardShadow}
                alt=""
                className="shadow-cardImg"
              />
            </div>
          </div>
          <div className="login_left-text_content">
            <div className="login_left-title">Introducing AutoPayouts 2.0®</div>
            <div className="login_left-text">
              Powerful, self-serve product and growth analytics to
              <br /> help you convert, engage, and retain more users.
            </div>
          </div>
        </div>
      </div>
  </Slider >
  
  );
}
