import {
    AUTH_USER,
    AUTH_USER_FAIL,
    LOGIN_SUCCESS,
    LOGOUT,
  } from "../action/types";
  
  const initialState = {
    loading: true,
    authenticated: false,
    user: null,
  };
  
  // eslint-disable-next-line default-param-last
  const auth = (state = initialState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case AUTH_USER:
        // localStorage.setItem("fstacData", JSON.stringify(payload));
        return {
          ...state,
          loading: false,
          authenticated: true,
          user: payload,
        };
      case AUTH_USER_FAIL:
        return {
          ...state,
          loading: false,
          authenticated: false,
        };
      case LOGIN_SUCCESS:
        localStorage.setItem("auth-token", payload);
        // localStorage.setItem("navigation", JSON.stringify(payload.permissions));
        return {
          ...state,
          loading: false,
          authenticated: true,
          user: payload,
        };
      case LOGOUT:
        localStorage.removeItem("fstacSecretKey");
        localStorage.removeItem("fstacMid");
        localStorage.removeItem("fstacData");
        return {
          ...state,
          loading: false,
          authenticated: false,
          user: null,
        };
      default:
        return state;
    }
  };
  
  export default auth;
  