import { useState, useRef } from "react";
import uploadFile from "../assets/images/imgIcon.png";
import "../styles/addPayout.css";
import "../styles/bulkUpload.css";
import Button from "@mui/material/Button";
import { bulkPayout } from "../action/payoutApi";
import { toast, Toaster } from "react-hot-toast";
import {
  downloadCsv,
  downloadCSVTemplate,
  validatePaymentsArray,
  createTemplate,
} from "../commonFunction/utils";
import { ShowToasts } from "../pages/errorMessage";
import { BeforShowToasts } from "../pages/beforeUploadError";
import scvicon from "../assets/images/csvIcon.png";
import deleteicon from "../assets/images/deleteIcon.png";
import { LinearProgressWithLabel } from "../pages/linerProgress";
import csv from "csvtojson";
import { ModalHeader } from "./modalHeader";
import closeIcon from "../assets/images/x-close.svg";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import { SingleErrorMessage } from "./singleErrorMessage";
import tickSign from "../assets/images/tickSign.svg";
import circleInfo from "../assets/images/info-circle.svg";
import { ReusableDialog } from "../common/dialog";
import { SuccessPayout } from "./successPayout";
import tickIcon from "../assets/images/tickIcon.svg";
import { useNavigate } from "react-router-dom";

export const ExportBulkPayout = ({ open, onClose, virtualBalance }) => {
  const handleClose = (data) => {
    onClose(data);
    setSelectedFile(null);
    setCountEnter({
      count: 0,
      sum: 0,
    });
  };
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(10);
  const [validationArray, setValidationArray] = useState([]);
  const [fileDataArray, setFileDataArray] = useState([]);
  const [resultData, setResultData] = useState("");

  const [countEnter, setCountEnter] = useState({
    count: 0,
    sum: 0,
  });

  const [isLoading, setIsLoading] = useState(false);

  const calculateCountAndSum = (jsonArray) => {
    let count = 0;
    let sum = 0;
    jsonArray.forEach((ele) => {
      count++;
      sum += Number(ele.amount);
    });

    // setCountEnter((e)=>{
    //   countEnter.count= count,
    //   countEnter.sum= sum,
    // });
    setCountEnter((prev) => ({
      ...prev,
      count: count,
      sum: sum,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    processFile(file);
  };
  const onCloseToaste = () => {
    toast.dismiss();
  };
  const bytesToKB = (bytes) => {
    return (bytes / 1024).toFixed(2);
  };

  const processFile = (file) => {
    console.log("file", file, file.name.endsWith(".csv"));
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        // File size exceeds 5MB, show an error message or handle accordingly
        toast.custom(
          <SingleErrorMessage
            data={"File size exceeds 5MB. Please select a smaller file."}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
        return;
      }
      if (!file.name.endsWith(".csv")) {
        toast.custom(
          <SingleErrorMessage
            data={"Please select a CSV file"}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );

        return;
      }
      const reader = new FileReader();

      reader.onload = async (e) => {
        const csvString = e.target.result;
        const jsonArray = await csv().fromString(csvString);
        setFileDataArray(jsonArray);
        if (jsonArray.length > 0) {
          const validation = validatePaymentsArray(jsonArray);
          setValidationArray(validation);
          if (validation.length > 0) {
            toast.custom(
              <SingleErrorMessage
                data={
                  "File upload failed. Please check fields and Re-upload it."
                }
                error={true}
                bColor="#D92D20"
                onClose={onCloseToaste}
              />
            );
            // BeforShowToasts(validation);
          } else {
            calculateCountAndSum(jsonArray);
            const timer = setInterval(() => {
              setProgress((prevProgress) => {
                if (prevProgress >= 100) {
                  clearInterval(timer);
                  return 100;
                } else {
                  return prevProgress + 10;
                }
              });
            }, 400);
          }
        } else {
          toast.custom(
            <SingleErrorMessage
              data={"file is empty"}
              error={true}
              bColor="#D92D20"
              onClose={onCloseToaste}
            />
          );
          // toast.error("file is empty");
        }
      };

      reader.readAsText(file);
      setSelectedFile(file);
    }
  };
  const handlePayoutBulk = () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
    bulkPayout(formData)
      .then((result) => {
        if (result.status === 1) {
          handleClose("Success");
          // toast.success(result.data);
          // toast.custom(
          //   <SingleErrorMessage
          //     data={result.message}
          //     error={false}
          //     bColor="#175FC9"
          //     onClose={onCloseToaste}
          //   />
          // );
          setResultData("");
          setOpenAfterSuccess(true);
          setIsLoading(false);
        }
        if (result.status === 0) {
          if (result.data.length > 0 && result.data) {
            ShowToasts(result.data);
            setIsLoading(false);
          } else {
            // toast.error(result.message);
            toast.custom(
              <SingleErrorMessage
                data={result.message}
                error={true}
                bColor="#D92D20"
                onClose={onCloseToaste}
              />
            );
            setIsLoading(false);
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const downloadCsv = () => {
    downloadCSVTemplate();
    // createTemplate();
  };
  const handleDelete = () => {
    setSelectedFile(null);
    setProgress(10);
    setCountEnter((prev) => ({
      ...prev,
      count: 0,
      sum: 0,
    }));
  };

  //  for drag and drop functionality

  const inputFileRef = useRef(null);

  const handleClick = () => {
    inputFileRef.current.click();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    processFile(file);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  const dialogStylesSucessPopUp = {
    width: "30%",
    maxWidth: "none",
  };

  const [openAfterSuccess, setOpenAfterSuccess] = useState(false);
  const handleCloseAfterSuccess = () => {
    setOpenAfterSuccess(false);
  };
  const handleClickBackHome = () => {
    navigate("/");
    setOpenAfterSuccess(false);
  };

  return (
    <div>
      {openAfterSuccess && (
        <ReusableDialog
          open={openAfterSuccess}
          onClose={handleCloseAfterSuccess}
          dialogStyles={dialogStylesSucessPopUp}
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={tickIcon} alt="" style={{ height: "40px" }} />
            </div>
          }
          body={
            <SuccessPayout
              data={resultData}
              handleClickBack={handleClickBackHome}
              type={""}
            />
          }
        />
      )}
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose("");
          }
        }}
      >
        <div className="modal-dialog" style={style}>
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              onClick={() => handleClose("")}
            />
            <div className="modal-body">
              <>
                <div className="imgUpload-head">
                  <div className="imgHeader_img">
                    <img src={uploadFile} alt="" />
                  </div>
                  <div className="imgUpload_text">
                    <div className="imgUpload_ttl">Upload File</div>
                    <div className="imgUpload_desc">
                      Select the file in the specified format.{" "}
                      <span>
                        <Button onClick={downloadCsv}>downLoad Sample</Button>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="upload_box d-flex justify-content-center">
                  {selectedFile && (
                    <>
                      <div style={{ width: "70%" }}>
                        <div
                          className="d-flex justify-content-center mt-5 "
                          style={{
                            border: "1px solid #ece8e8",
                            borderRadius: "8px",
                            padding: "10px",
                            gap: "10px",
                          }}
                        >
                          <div className="" style={{ width: "15%" }}>
                            <img
                              style={{
                                objectFit: "contain",
                              }}
                              src={scvicon}
                              alt=""
                            />
                          </div>
                          <div className="" style={{ width: "80%" }}>
                            <div className="">
                              <div className=" justify-content-between d-flex">
                                <div>
                                  <p>{selectedFile ? selectedFile.name : ""}</p>
                                  <p>
                                    {bytesToKB(
                                      selectedFile ? selectedFile.size : ""
                                    ) + "KB"}
                                  </p>
                                </div>
                                <div onClick={handleDelete}>
                                  <img
                                    style={{ height: "15px", width: "15px" }}
                                    src={deleteicon}
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="pt-3">
                                <LinearProgressWithLabel
                                  condition={
                                    validationArray.length > 0 ||
                                    fileDataArray.length === 0
                                  }
                                  value={progress}
                                />
                              </div>
                            </div>
                            <div>
                              {countEnter.sum > 0 &&
                                countEnter.count > 0 &&
                                progress === 100 && (
                                  <>
                                    <div className="d-flex justify-content-between pt-2">
                                      <div
                                        className="entries"
                                        style={{ paddingRight: "20px" }}
                                      >
                                        <p style={{ marginBottom: "0px" }}>
                                          <span>
                                            <img src={tickSign} alt="" />
                                            &nbsp;
                                          </span>
                                          <span style={{ color: "#079455" }}>
                                            {countEnter.count}{" "}
                                            {countEnter.count === 1
                                              ? "entry"
                                              : "entries"}
                                          </span>{" "}
                                          found
                                        </p>
                                      </div>
                                      <div className="amount">
                                        <p style={{ marginBottom: "0px" }}>
                                          Total Payout :
                                          <span style={{ color: "#079455" }}>
                                            &#8377;&nbsp; {countEnter.sum}
                                          </span>{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                )}
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-center mt-2">
                          <p style={{ marginBottom: "0px" }}>
                            Total Balance :{" "}
                            <span style={{ color: "#079455" }}>
                              &#8377;&nbsp; {virtualBalance}{" "}
                              <span>
                                &nbsp;
                                <img src={circleInfo} alt="" />
                              </span>
                            </span>
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                  {!selectedFile && (
                    <div
                      className="upload_content"
                      onClick={handleClick}
                      onDragOver={handleDragOver}
                      onDrop={handleDrop}
                    >
                      <div className="upload_btn">
                        <img src={uploadFile} alt="" />
                      </div>
                      <div className="upload_text">
                        <span className="upload_btn-txt">
                          <input
                            type="file"
                            hidden=""
                            accept=".csv"
                            id="fileID"
                            ref={inputFileRef}
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                          />
                          Click to upload &nbsp;
                        </span>
                        or drag and drop
                      </div>
                      <div className="file_format">
                        File format: <span>csv</span> (max. file size{" "}
                        <span>5MB</span>)
                      </div>
                    </div>
                  )}
                </div>
              </>
            </div>
            <div className="benyf_btn mb-3 px-4">
              <div className="beny_left-btn" onClick={() => handleClose("")}>
                <img src={closeIcon} alt="" className="close" />
                Close
              </div>
              <div
                className={`beny_right-btn ${
                  countEnter.sum > 0 && countEnter.count > 0
                    ? `${!isLoading ? "active" : ""}`
                    : "newClass"
                }`}
                onClick={handlePayoutBulk}
              >
                Proceed {isLoading ? <CircularProgress size={20} /> : ""}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
