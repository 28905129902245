import { requestWithToken, requestWithoutToken } from "../helper/helper";

const getPayout = async (limit, page, body) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/api/payment/transferList?limit=${limit}&page=${page}`,
      body
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getDetailById = async (body) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/api/payment/transferById`,
      body
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getVirtualAccountNumber = async () => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/payment/virtualAccountNumber`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getBeneficiaryList = async (select, searchValue) => {
  try {
    let response;
    if (select && searchValue) {
      response = await requestWithToken(
        "GET",
        `/api/payment/beneficiaryList?${select}=${searchValue}`
      );
    } else {
      response = await requestWithToken("GET", `/api/payment/beneficiaryList`);
    }

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const bulkPayout = async (formData) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/api/payment/payout`,
      formData
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getTodayTransaction = async () => {
  try {
    const response = await requestWithToken(
      "GET",
      `/api/payment/todayTransaction`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const exportPayoutData = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/api/payment/transferListExport `,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  getPayout,
  getVirtualAccountNumber,
  getDetailById,
  getBeneficiaryList,
  bulkPayout,
  getTodayTransaction,
  exportPayoutData,
};
