import { toast, Toaster } from "react-hot-toast";
export const ShowToasts = (dataArray) => {
  dataArray.forEach((item) => {
    const { message, data } = item;
    const errorMessage = (
      <div>
        <strong style={{ color: "#e74c3c" }}>{message}:</strong>
        <p>Beneficiary Name: {data.beneficiary_name}</p>
        <p>Payment Mode: {data.payment_mode}</p>
        <p>Amount: {data.amount}</p>
        {data.payment_mode === "UPI" ? (
          <p>UPI Handle: {data.upi_handle || "------"}</p>
        ) : (
          <div>
            <p>Account Number: {data.account_number || "------"}</p>
            <p>IFSC: {data.ifsc || "------"}</p>
          </div>
        )}
        <p>Narration: {data.narration}</p>
      </div>
    );
    toast.error(errorMessage, {
      duration: 5000,
      position: "top-right",
    });
  });
};
