import { requestWithToken, requestWithoutToken } from "../helper/helper";



const  addBeneficiaryByUpi = async (body) => {
    try {
      const response = await requestWithToken(
        "POST",
        `/api/payment/beneficiaryAccount`,
        body
      
      );
  
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  };


  
const  initiateTransfer = async (body) => {
    try {
      const response = await requestWithToken(
        "POST",
        `/api/payment/initiateTransfer`,
        body
      
      );
  
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  };



  


  
  export {
   addBeneficiaryByUpi,
   initiateTransfer
  }
