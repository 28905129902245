import { Link } from "react-router-dom";

import "../styles/successPayout.css";
import upiIcon from "../assets/images/upiSuccess.png";
import closeIcon from "../assets/images/x-close.svg";
import arrowLeft from "../assets/images/chevron-left.svg";
import arrowRight from "../assets/images/chevron-right.svg";

export const UpiSuccess = ({ data, status, handleClickBack }) => {
  return (
    <div style={{ position: "relative" }}>
      <div style={{ display: "flex", alignItems: "center" }}></div>
      <div
        className="successPayout"
        style={{
          width: "370px",
        }}
      >
        <div className="imageSuccess">
          <img style={{ height: "100px" }} src={upiIcon} alt="" />
        </div>
        <div>
          <h6 style={{ textAlign: "center" }}>
            Beneficiary Added Successfully!
          </h6>
        </div>
        {status === "BANK" && (
          <div className="addBankAccount pt-3">
            <div className="d-flex justify-content-between">
              <div>
                <p>Beneficiary Name</p>
              </div>
              <div>
                <h6>{data.account_name}</h6>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <p>Beneficiary A/C</p>
              </div>
              <div>
                <h6>{data.account_number}</h6>
              </div>
            </div>
          </div>
        )}
        {status === "UPI" && (
          <div>
            <p
              style={{
                fontSize: "12px",
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              Banks generally take 30 mins to 4 hrs to authenticate beneficiary
              details. We will notify you, once bank approves{" "}
            </p>
          </div>
        )}

        <div className="pt-3 w-100 d-flex justify-content-center">
          {/* <div className="benyf_btn" >
            <div className="beny_right-btn active ">Back to Dashboard</div>
          </div> */}
          <div className="benyf_btn">
            <div
              className="beny_left-btn "
              style={{ fontSize: "11px" }}
              onClick={() => {
                handleClickBack("CLOSE");
              }}
            >
              <img src={closeIcon} alt="" className="close" />
              Close
            </div>
            {status === "UPI" ? (
              <div
                className="beny_right-btn active"
                style={{ fontSize: "11px" }}
                onClick={() => {
                  handleClickBack("UPI");
                }}
              >
                Initiate Payment
                <img src={arrowRight} alt="" className="bany_next" />
              </div>
            ) : (
              <div
                className="beny_right-btn active"
                style={{ fontSize: "11px" }}
                onClick={() => {
                  handleClickBack("BANK");
                }}
              >
                Initiate Payment
                <img src={arrowRight} alt="" className="bany_next" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
