import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "../styles/successPayout.css";
import { useNavigate, Link } from "react-router-dom";
import tickIcon from "../assets/images/tickIcon.svg";

export const SuccessPayout = ({ data, handleClickBack, type }) => {
  // if (!data || Object.keys(data).length === 0) {
  //   return null;
  // }

  return (
    <div style={{ position: "relative" }}>
      <div className="payout_success_pop">
        {type === "SINGLEPAY" ? (
          <>
            <div className="payout_success-ttl">Payout Successfully Added</div>
            <div className="payout_success-content">
              <div className="payout_cont-text">
                <div className="payout_cont-card-text">Payment Method</div>
                <div className="payout_cont-card-ttl">
                  {data.payment_mode ?? "-"}
                </div>
              </div>

              <div className="payout_cont-text">
                <div className="payout_cont-card-text">Beneficiary Name</div>
                <div className="payout_cont-card-ttl">
                  {data.beneficiary_account_name ?? "-"}
                </div>
              </div>
              {data.beneficiary_account_number !== null ? (
                <>
                  <div className="payout_cont-text">
                    <div className="payout_cont-card-text">Account Number</div>
                    <div className="payout_cont-card-ttl">
                      {data.beneficiary_account_number ?? "-"}
                    </div>
                  </div>
                  <div className="payout_cont-text">
                    <div className="payout_cont-card-text">Bank IFSC</div>
                    <div className="payout_cont-card-ttl">
                      {data.beneficiary_account_ifsc ?? "-"}
                    </div>
                  </div>
                </>
              ) : (
                <div className="payout_cont-text">
                  <div className="payout_cont-card-text">UPI Handle</div>
                  <div className="payout_cont-card-ttl">
                    {data.beneficiary_upi_handle ?? "-"}
                  </div>
                </div>
              )}

              <div className="payout_cont-text">
                <div className="payout_cont-card-text">Amount</div>
                <div className="payout_cont-card-ttl">
                  {data.amount ? data.amount + "/-" : "0 /-"}
                </div>
              </div>
              <div className="payout_cont-text">
                <div className="payout_cont-card-text">Remarks</div>
                <div className="payout_cont-card-ttl">
                  {data.narration ?? "-"}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="payout_success-ttl">
              BulkPayout Successfully Added
            </div>
            <div className="payout_success-content">{data}</div>
          </>
        )}
        <div onClick={handleClickBack} className="beny_right-btn active">
          Back to Dashboard
        </div>
      </div>
    </div>
  );
};
