import { useState, useEffect } from "react";
import { ReusableDialog } from "../common/dialog";
import "../styles/summary.css";
import Button from "@mui/material/Button";
import zap from "../assets/images/zap.svg";
import verified from "../assets/images/verified.svg";
import { modifiedStatus } from "../model/tableModel";

import check from "../assets/images/check-gr.svg";

export const ShowRowData = ({ openDialog, handleClose, data }) => {
  const statusColorMapping = {
    Accepted: "successful",
    failure: "failed",
    Failed: "failed",
    Processing: "processing",
    Pending: "pending",
    Rejected: "rejected",
    Reversed: "reverse",
    Success: "successful",
  };

  // const statusColorMapping = {
  //   Accepted: "success",
  //   failure: "failed",
  //   failed: "failed",
  //   Processing: "processing",
  //   Pending: "pending",
  //   Rejected: "rejected",
  //   Reversed: "reverse",
  //   Success: "success",
  // };

  const getStatusClassName = (status) => {
    return statusColorMapping[status] || "";
  };

  const dialogStyles = {
    position: "fixed",
    top: 0,
    right: 0,
    height: "100%",
    width: "30%",
    maxWidth: "50%",
    maxHeight: "100%",
    overflowY: "auto",
    margin: "0px",
    borderRadius: "0px",
    padding: "0px",
  };
  return (
    <div
      className={`offcanvas offcanvas-end ${openDialog ? "show" : ""}`}
      tabIndex={-1}
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div className="offcanvas-header">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={handleClose}
        />
      </div>
      <div className="offcanvas-body">
        <div className="transaction_ttl">Transaction Summary</div>
        <div className="transaction_varified">
          <div className="success_varified">
            <div className="success_varified-head">
              <div className="sucees_varified_left">
                <div
                  className={`${getStatusClassName(
                    modifiedStatus(data?.status)
                  )}`}
                >
                  {(data?.status === "success" ||
                    data?.status === "accepted") && (
                    <img src={check} alt="" className="successful_img" />
                  )}
                  {modifiedStatus(data?.status)}
                </div>
              </div>
              <div className="sucees_varified_right">
                <img src={verified} alt="" /> Beneficiary Verified
              </div>
            </div>
            <div className="benificial_accName">Beneficiary A/c Name</div>
            <div className="benificial_acc-ttl">
              {data?.beneficiary_account_name ?? "- "}{" "}
            </div>
            <div className="create_time">Created: {data?.createdAt}</div>
          </div>
          <div className="sec_remark">
            <div className="sec_remark-ttl">Remarks</div>
            <div className="sec_remark-desc">{data?.narration ?? "-"}</div>
          </div>
          <div className="payout_success_pop">
            <div className="payout_success-ttl">Transaction Related</div>
            <div className="payout_success-content">
              <div className="payout_cont-text">
                <div className="varified_cont-card-text">Beneficiary ID</div>
                <div className="varified_cont-card-ttl">{data?.id ?? "-"}</div>
              </div>
              <div className="payout_cont-text">
                <div className="varified_cont-card-text">
                  Beneficiary Bank Name
                </div>
                <div className="varified_cont-card-ttl">
                  {data?.beneficiary_account_name ?? "-"}
                </div>
              </div>
              <div className="payout_cont-text">
                <div className="varified_cont-card-text">
                Source Virtual Account
                </div>
                <div className="varified_cont-card-ttl">
                  {data?.source_virtual_account ?? "-"}
                </div>
              </div>
              
              {data?.beneficiary_account_number && (
                <div className="payout_cont-text">
                  <div className="varified_cont-card-text">
                    Beneficiary Account Number
                  </div>
                  <div className="varified_cont-card-ttl">
                    {data?.beneficiary_account_number}
                  </div>
                </div>
              )}
              {data?.beneficiary_upi_handle && (
                <div className="payout_cont-text">
                  <div className="varified_cont-card-text">UPI Handle</div>
                  <div className="varified_cont-card-ttl">
                    {data?.beneficiary_upi_handle}
                  </div>
                </div>
              )}
              <div className="payout_cont-text">
                <div className="varified_cont-card-text">Transaction ID</div>
                <div className="varified_cont-card-ttl">{data?.id ?? "-"}</div>
              </div>
              <div className="payout_cont-text">
                <div className="varified_cont-card-text">UTR</div>
                <div className="varified_cont-card-ttl">
                  {data?.unique_transaction_reference ?? "-"}
                </div>
              </div>
              <div className="payout_cont-text">
                <div className="varified_cont-card-text">Payment Method</div>
                <div className="mps_card">
                  {" "}
                  <img src={zap} alt=" " />
                  {data?.payment_mode ?? "-"}
                </div>
              </div>
              <div className="payout_cont-text">
                <div className="varified_cont-card-text">Amount</div>
                <div className="varified_cont-card-ttl">
                  {" "}
                  &#8377;&nbsp;
                  {data?.amount ?? "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
