import {
  createBrowserRouter,
  createRoutesFromElements,
  BrowserRouter,
  Link,
  Route,
  Routes,
  RouterProvider,
} from "react-router-dom";

import { Login } from "../pages/login";
import { SignUp } from "../pages/signUp";
import { DashboardLayout } from "../pages/dashboardLayout";
import { Payout } from "../pages/payout";
import { ProtectedRoute } from "../routes/protectedRoutes";
import { PageNotFound } from "../pages/404Page";

const route = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="login" element={<Login />} />
      {/* <Route path="signup" element={<SignUp />} /> */}
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <DashboardLayout />
          </ProtectedRoute>
        }
      >
        <Route index path="" element={<Payout />} />
        <Route path="/home" element={<Payout />} />
        <Route path="/developerPortal" element={<Payout />} />
        <Route path="/file" element={<Payout />} />
        <Route path="/report" element={<Payout />} />
        <Route path="/onBoarding" element={<Payout />} />
        <Route path="/payIn" element={<Payout />} />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Route>
  )
);

export default route;
