
import axios from "axios";



export const requestWithoutToken = async (method, url, body) => {
    let request;
    url = process.env.REACT_APP_API_ENDPOINT + url;
  
  
    switch (method) {
      case "GET":
        request = await axios.get(url);
        break;
      case "POST":
        request = await axios.post(url, body);
        break;
      default:
        break;
    }
  
    return request;
  };
  
  export const requestWithToken = async (method, url, body, responseType, reqHeader, baseUrlType) => {
    let request;
    url = process.env.REACT_APP_API_ENDPOINT + url
  
    let headers = {};
    if (reqHeader) {
      headers = {
        headers: {
          'X-MID': localStorage.getItem("fstacMid")
        },
      };
    } else {
      headers = {
        headers: {
          'X-MID': localStorage.getItem("fstacMid")
        },
      };
    }
    if (responseType) {
      headers[`responseType`] = responseType;
    }
  
    switch (method) {
      case "GET":
        request = await axios.get(url, headers);
        break;
      case "POST":
        request = await axios.post(url, body, headers);
        break;
      case "PUT":
        request = await axios.put(url, body, headers);
        break;
      case "DELETE":
        request = await axios.delete(url, {
          data: body,
          headers: headers.headers,
        });
        break;
  
      default:
        break;
    }
  
    return request;
  };