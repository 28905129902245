import {
  formatDateTime,
  formatCurrentDate,
  formatDateTable,
} from "../commonFunction/utils";

export class PayoutModelData {
  constructor(data) {
    this.createdOn = formatDateTable(data.createdAt);
    this.amount = data.amount;
    this.status = modifiedStatus(data.status);
    this.beneficiary = data.beneficiary_account_name;
    this.createdBy = data.beneficiary_account_name;
    this.urn = data.unique_request_number;
    this.utr = data.unique_transaction_reference ?? "-";
    this.payout = data.batchId ? "Bulk" : "Manual";
    this.singleData = data;
  }
}

export const modifiedStatus = (status) => {
  if (status === "in_process") {
    return "Processing";
  }
  if (status === "accepted") {
    return "Accepted";
  }
  if (status === "pending") {
    return "Pending";
  }
  if (status === "failure" || status === "failed") {
    return "Failed";
  }
  if (status === "rejected") {
    return "Rejected";
  }
  if (status === "success") {
    return "Success";
  }
  if (status === "reversed") {
    return "Reversed";
  }
  return "";
};

// Status should be -- Processing , success , active	Failed -- different status text -- in process , accepted , pending

export class NewPayoutModelData {
  constructor(data) {
    this.createdOn = formatDateTime(data.created_at);
    this.id = data.id;
    this.amount = data.transaction_amount;
    this.mode = data.payment_mode;
    this.beneficiary = data.beneficiary_full_name;
    this.createdBy = data.created_by ?? "N/A";
    this.transactionType = data.transaction_type;
    this.utr = data.unique_transaction_reference ?? "N/A";
    this.singleData = data;
  }
}
