import { useState, useEffect } from "react";
import { ReusableDialog } from "../common/dialog";
import "../styles/payout.css";
import "../styles/style.css";
import Button from "@mui/material/Button";
import * as React from "react";
import Box from "@mui/material/Box";
import { getBeneficiaryList } from "../action/payoutApi";
import { BeneficiaryModel } from "../model/beneficiaryListModel";
import * as yup from "yup";
import { useFormik } from "formik";
import { Beneficiary } from "../pages/addBenifiary";
import { initiateTransfer } from "../action/beneficiary";
import { base64 } from "../commonFunction/utils";
import { toast } from "react-hot-toast";
import { SuccessPayout } from "../pages/successPayout";
import payoutIcon from "../assets/images/createPayout.png";
import "../styles/addPayout.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircularProgress from "@mui/material/CircularProgress";
import upi from "../assets/images/upi.png";
import neft from "../assets/images/neft.png";
import imps from "../assets/images/imps.png";
import rtgs from "../assets/images/rtgs.png";
import paym1 from "../assets/images/pay_m1.svg";
import paym2 from "../assets/images/pay_m2.svg";
import paym3 from "../assets/images/pay_m3.svg";
import paym4 from "../assets/images/pay_m4.svg";
import currenyRupees from "../assets/images/currency-rupee.svg";
import anotationDot from "../assets/images/annotation-dots.svg";
import trash from "../assets/images/trash-03.svg";
import edit from "../assets/images/edit-02.svg";
import transArrow from "../assets/images/trans_arrow.svg";
import search from "../assets/images/search-lg.svg";
import tickIcon from "../assets/images/tickIcon.svg";
import crossIcon from "../assets/images/closeGrey.svg";
import checkGr from "../assets/images/check-gr.svg";
import closeRed from "../assets/images/x-close-red.svg";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import stepperTickIcon from "../assets/images/stepperTick.svg";

import { OtpInput } from "./otpInput";

import { useNavigate } from "react-router-dom";

import { ModalHeader } from "./modalHeader";
import { SingleErrorMessage } from "./singleErrorMessage";

const steps = ["Beneficiary Details", "Add Amount", "Confirm Payout"];

export const AddPayout = ({
  open,
  onClose,
  virtualBalance,
  openAddBeneficiary,
  initiateData,
}) => {
  const [selectValue, setSelectValue] = useState("Account Number");
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  //OPEN CLOSE BENEFICIARY
  const [beneficiaryOpen, setBeneficiaryOpen] = useState(false);

  const openBeneficiary = () => {
    openAddBeneficiary();
    onClose();
    formikPayoutFrom.resetForm();
  };

  const navigate = useNavigate();

  const handleClose = (data) => {
    onClose(data);
    if (data === "Success") {
      resetValue();
    }
    resetValue();
  };

  useEffect(() => {
    if (!open) {
      // Reset the necessary data here
      resetValue();
    }
  }, [open]);

  const resetValue = () => {
    setSearchValue("");
    setSelected([]);
    setSelectData([]);
    setSelectedMethod("");
    setActiveStep(0);
    closePopUpResetData();
    setOtp("");
    setSelected("");
    setIsHidden(false);
    formikPayoutFrom.resetForm();
  };

  const [isHidden, setIsHidden] = useState(false);
  const [isCheckBox, setCheckBox] = useState(false);

  useEffect(() => {
    const storedValue = localStorage.getItem("hideContent");
    setIsHidden(storedValue === "true");
  }, []);

  const handleCheckboxChange = (event) => {
    setCheckBox(event.target.checked);
  };

  const closeCrossIcon = () => {
    setIsHidden(true);
  };

  const dismissCross = () => {
    setIsHidden(true);
    if (isCheckBox) {
      localStorage.setItem("hideContent", true);
    }
  };

  const handleClickBackHome = () => {
    navigate("/home");
    handleCloseAfterSuccess();
  };

  // ------------------end open close------------

  const paymentMethods = [
    { label: "UPI", image: upi },
    { label: "NEFT", image: neft },
    { label: "IMPS", image: imps },
    { label: "RTGS", image: rtgs },
  ];

  const [selectedMethod, setSelectedMethod] = useState("");
  useEffect(() => {
    getBeneficiaryListData();
    if (initiateData !== null) {
      if (initiateData) {
        setSelectedMethod(initiateData.type === "UPI" ? "UPI" : "IMPS");
        setActiveStep(1);
      }
      setActiveStep(1);
    }
  }, [selectValue, searchValue, open]);

  const handleChangePaymentMethod = (event) => {
    const selectedMethod = event.label;
    setSelected([]);
    const updatedBeneficiaryList = beneficiaryList.map((item) => {
      if (selectedMethod === "UPI") {
        item.isDisabled = item.upiHandle === "N/A";
      } else if (["IMPS", "NEFT", "RTGS"].includes(selectedMethod)) {
        item.isDisabled = item.accountNumber === "N/A";
      }
      return item;
    });
    setSelectedMethod(selectedMethod);
    setBeneficiaryList(() => updatedBeneficiaryList);
  };

  const updateListOfBeneficiary = (data) => {
    const updatedBeneficiaryList = data.map((item) => {
      if (selectedMethod === "UPI") {
        item.isDisabled = item.upiHandle === "N/A";
      } else if (["IMPS", "NEFT", "RTGS"].includes(selectedMethod)) {
        item.isDisabled = item.accountNumber === "N/A";
      }
      return item;
    });

    setBeneficiaryList(() => updatedBeneficiaryList);
    initiateProcess(updatedBeneficiaryList);
  };

  const closePopUpResetData = () => {
    const updatedBeneficiaryList = beneficiaryList.map((item) => {
      item.isDisabled = true;
      return item;
    });
    setBeneficiaryList(() => updatedBeneficiaryList);
  };

  // -------------------------------table code----------------------------

  const [beneficiaryList, setBeneficiaryList] = useState([]);

  const initiateProcess = (dataList) => {
    if (initiateData) {
      let value;
      if (initiateData?.type !== "UPI") {
        value = dataList.filter(
          (ele) => ele.accountNumber === initiateData.data.account_number
        );
      } else {
        value = dataList.filter(
          (ele) => ele.upiHandle === initiateData.data.upi_handle
        );
      }

      if (value) {
        setSelectData(value);
        const isSelected = selected.includes(value[0].id);
        const newSelected = isSelected ? [] : [value[0].id];
        setSelected(newSelected);
      }
      let selectedMethodValue = initiateData.type === "UPI" ? "UPI" : "IMPS";
      const updatedBeneficiaryList = dataList.map((item) => {
        if (selectedMethodValue === "UPI") {
          item.isDisabled = item.upiHandle === "N/A";
        } else if (["IMPS", "NEFT", "RTGS"].includes(selectedMethodValue)) {
          item.isDisabled = item.accountNumber === "N/A";
        }
        return item;
      });

      setBeneficiaryList(() => updatedBeneficiaryList);
    }
  };

  const getBeneficiaryListData = () => {
    getBeneficiaryList(getSelectedValue(selectValue), searchValue)
      .then((result) => {
        if (result.message === "Success") {
          updateListOfBeneficiary(
            result.data.map((ele) => new BeneficiaryModel(ele))
          );
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const getSelectedValue = (value) => {
    const selectedElement = selectDataValue.find((ele) => ele.name === value);
    return selectedElement ? selectedElement.value : "";
  };

  const [selected, setSelected] = useState([]);

  const [selectData, setSelectData] = useState([]);

  const handleRowCheckboxChange = (event, id) => {
    const isSelected = selected.includes(id);
    const newSelected = isSelected ? [] : [id];

    // Filter data based on the new selected ID
    const filteredData = beneficiaryList.filter((row) =>
      newSelected.includes(row.id)
    );

    setSelectData(filteredData);

    setSelected(newSelected);
  };

  // ------------------------------end table logic----------------------------------

  // ------------------------------stepper------------------------------------------

  const [activeStep, setActiveStep] = useState(0);

  const [openAfterSuccess, setOpenAfterSuccess] = useState(false);

  const [successPayoutData, setSuccessPayoutData] = useState("");
  const handleCloseAfterSuccess = () => {
    setOpenAfterSuccess(false);
  };

  const [activeSteps, setActiveSteps] = useState([true, false, false]);
  const onCloseToaste = () => {
    toast.dismiss();
  };
  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const nextActiveSteps = activeSteps.map((step, index) =>
      index === activeSteps.length - 1 ? true : step
    );
    setActiveSteps(nextActiveSteps);

    // setSkipped(newSkipped);

    if (activeStep === steps.length - 1) {
      setIsLoading(true);
      const payload = {
        payment_mode: selectedMethod,
        beneficiary_code: selectData[0].id,
        amount: Number(formikPayoutFrom.values.amount).toFixed(2),
        beneficiary_name: selectData[0].name,
        remarks: formikPayoutFrom.values.remark,
      };

      initiateTransfer({ data: base64(payload) })
        .then((result) => {
          if (result.message == "Success") {
            setSuccessPayoutData(result.data);
            setOpenAfterSuccess(true);

            handleClose("Success");
            setIsLoading(false);
          } else {
            toast.custom(
              <SingleErrorMessage
                data={result.message}
                error={true}
                bColor={"red"}
                onClose={onCloseToaste}
              />
            );
            setIsLoading(false);
          }
        })
        .catch((err) => {
          toast.custom(
            <SingleErrorMessage
              data={"something went wrong"}
              error={true}
              bColor={"red"}
              onClose={onCloseToaste}
            />
          );
          // toast.error("something went wrong");
        });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    const prevActiveSteps = activeSteps.map((step, index) =>
      index === 0 ? false : step
    );
    setActiveSteps(prevActiveSteps);
  };

  // -----------------------------------end-stepper-------------------------

  //===============================otp======================================

  const [otp, setOtp] = useState("");

  const handleOtpChange = (otpValue) => {
    setOtp(otpValue);
  };

  const otpValidation = () => {
    return otp.length === 5;
  };

  //===============================end_otp==================================
  // validation

  useEffect(() => {
    isFormValid();
  }, [selectedMethod, selected, activeStep]);

  const isFormValid = () => {
    return selectedMethod === "" || selected.length === 0; // True if a payment method is selected
  };

  // ----------------------activeTab1----------------------------

  const validationSchema = yup.object({
    amount: yup
      .string()
      .required("Amount is required")
      .matches(
        /^\d+(\.\d{1,2})?$/,
        "Amount must be a valid number with up to two decimal places"
      ),
    remark: yup
      .string()
      .required("remarks is required")
      .max(100, "Remarks must be at most 100 characters"),
  });

  // Initialize useFormik

  const formikPayoutFrom = useFormik({
    initialValues: {
      amount: "",
      remark: "",
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {},
  });

  const validation1 = () => {
    return (
      formikPayoutFrom.values.amount === "" ||
      formikPayoutFrom.values.remark === ""
    );
  };

  useEffect(() => {
    validation1();
  }, [formikPayoutFrom.values.amount, formikPayoutFrom.values.remark]);

  const [toggleSearch, setToggleSearch] = useState(false);
  const handleToggleSearch = () => {
    setToggleSearch(!toggleSearch);
  };
  const selectDataValue = [
    { name: "Account Number", value: "account_number" },
    { name: "Beneficiary Name", value: "beneficiary_name" },
  ];

  const handleClosePayOut = () => {
    handleClose("");
  };

  const dialogStyles = {
    width: "65%",
    maxWidth: "none",
  };
  const dialogStylesSucessPopUp = {
    width: "30%",
    maxWidth: "none",
  };

  return (
    <div>
      {openAfterSuccess && (
        <ReusableDialog
          open={openAfterSuccess}
          onClose={handleCloseAfterSuccess}
          dialogStyles={dialogStylesSucessPopUp}
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={tickIcon} alt="" style={{ height: "40px" }} />
            </div>
          }
          body={
            <SuccessPayout
              data={successPayoutData}
              handleClickBack={handleClickBackHome}
              type={"SINGLEPAY"}
            />
          }
        />
      )}
      <ReusableDialog
        open={open}
        onClose={() => handleClose("")}
        dialogStyles={dialogStyles}
        title={
          <div className="create_payout-head">
            <div className="create_payout-head_left">
              <ModalHeader
                image={payoutIcon}
                title={"Create Payout"}
                subTitle={" Manage your Payouts, at ease."}
              />
            </div>

            <div className="create_payout-progresBar">
              <div
                className={`progress_card ${activeStep >= 0 ? "active" : ""}`}
              >
                {isFormValid() && (
                  <div className="progress_fill">
                    <div className="progress_blue_box">
                      <div className="progress_white_box"></div>
                    </div>
                  </div>
                )}
                {!isFormValid() && (
                  <div>
                    <img className="prf2" src={stepperTickIcon} alt="" />
                  </div>
                )}

                <div className="progress_sec">Beneficiary Details</div>
              </div>
              <div
                className={`progress_card ${activeStep >= 1 ? "active" : ""}`}
              >
                {!formikPayoutFrom.isValid || !formikPayoutFrom.dirty ? (
                  <div className="progress_fill prf2">
                    <div className="progress_blue_box">
                      <div className="progress_white_box"></div>
                    </div>
                  </div>
                ) : (
                  <img className="prf2" src={stepperTickIcon} alt="" />
                )}

                <div className="progress_sec">Add Amount</div>
              </div>
              <div
                className={`progress_card ${activeStep >= 2 ? "active" : ""}`}
              >
                {true && (
                  <div className="progress_fill prf2">
                    <div className="progress_blue_box">
                      <div className="progress_white_box"></div>
                    </div>
                  </div>
                )}
                {false && <img className="prf2" src={stepperTickIcon} alt="" />}

                <div className="progress_sec">Confirm Payout</div>
              </div>
            </div>
          </div>
        }
        body={
          <>
            <React.Fragment>
              {activeStep === 0 ? (
                <>
                  <div className="baneficial_details">
                    <div className="payment_mode">
                      <div className="payment_title">
                        Select Payment Mode<span>*</span>
                      </div>
                      <div className="payment_cards">
                        {paymentMethods.map((ele) => (
                          <div
                            key={ele.label}
                            className={`payment_card  ${
                              selectedMethod === ele.label ? "active" : ""
                            }`}
                            onClick={() => {
                              handleChangePaymentMethod(ele);
                            }}
                          >
                            <div className="payment_cardImg">
                              <img
                                src={ele.image}
                                alt=""
                                className="payCardImg"
                              />
                            </div>
                            <div className="payment_card_text">{ele.label}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="baneficiary">
                      <div className="baneficiary_head">
                        <div className="banificiary_top">
                          <div className="bani_ttl">Select Beneficiary</div>
                          <div
                            className="add_beni_btn"
                            onClick={openBeneficiary}
                          >
                            + Add Beneficiary
                          </div>
                        </div>
                        <div className="banificiary_bottom">
                          <div
                            className={`transaction_id ${
                              toggleSearch ? "active" : ""
                            } `}
                            onClick={handleToggleSearch}
                          >
                            {selectValue}
                            <img
                              src={transArrow}
                              alt=""
                              className="transArrow"
                            />
                            <div className="transDrop">
                              {selectDataValue.map((ele) => (
                                <div
                                  key={ele.value}
                                  className="transDrop-item"
                                  onClick={() => {
                                    setSelectValue(ele.name);
                                  }}
                                >
                                  {ele.name}
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="searchbox">
                            <img src={search} alt="" className="search_icon" />
                            <input
                              type="text"
                              className="seachInput"
                              placeholder="Search"
                              value={searchValue}
                              onChange={(e) => {
                                setSearchValue(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="baneficiary_table-box">
                        <table className="beneficial_table">
                          <tbody>
                            <tr className="tableHead">
                              <th>
                                <div className="payoutHead">
                                  {/* <input
                                    type="checkbox"
                                    className="bene_table-check"
                                  /> */}
                                  Payout Name{" "}
                                  <img
                                    src="/assets/images/arrow-down.svg"
                                    alt=""
                                    className="payoutHead_icon"
                                  />
                                </div>
                              </th>
                              <th>
                                <div className="payoutHead">
                                  Account Number{" "}
                                </div>
                              </th>
                              <th>
                                <div className="payoutHead">IFSC Code </div>
                              </th>
                              <th>
                                <div className="payoutHead">UPI Handle </div>
                              </th>
                              {/* <th>
                                <div className="payoutHead">Actions </div>
                              </th> */}
                            </tr>
                            {beneficiaryList.length > 0 ? (
                              beneficiaryList.map((item) => (
                                <tr
                                  key={item.id}
                                  className={`${
                                    selectedMethod && item.isDisabled
                                      ? "blur-row "
                                      : ""
                                  } ${
                                    selected.includes(item.id)
                                      ? "highlightRow"
                                      : ""
                                  }`}
                                  onClick={(event) =>
                                    handleRowCheckboxChange(event, item.id)
                                  }
                                >
                                  <td className="payout_name">
                                    <input
                                      type="checkbox"
                                      className="bene_table-check"
                                      checked={selected.includes(item.id)}
                                      disabled={item.isDisabled}
                                      onChange={(event) =>
                                        handleRowCheckboxChange(event, item.id)
                                      }
                                    />
                                    {item.name}
                                  </td>
                                  <td>
                                    {item.accountNumber !== "N/A"
                                      ? item.accountNumber
                                      : "-"}
                                  </td>
                                  <td>
                                    {" "}
                                    {item.ifsc !== "N/A" ? item.ifsc : "-"}
                                  </td>
                                  <td>
                                    {" "}
                                    {item.upiHandle !== "N/A"
                                      ? item.upiHandle
                                      : "-"}
                                  </td>
                                  {/* <td>
                                    <div className="action">
                                      <div className="edit_action">
                                        <img
                                          src={trash}
                                          alt=""
                                          className="action_img"
                                        />
                                      </div>
                                      <div className="edit_action">
                                        <img
                                          src={edit}
                                          alt=""
                                          className="action_img"
                                        />
                                      </div>
                                    </div>
                                  </td> */}
                                </tr>
                              ))
                            ) : (
                              <>
                                <tr>
                                  <td colSpan="6" className="text-center">
                                    No data available
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              ) : activeStep === 1 ? (
                <>
                  <div className="add_amount">
                    <div className="payment_methoed">
                      <div className="pay_methoed-card-box">
                        <div className="pay_methoed-card">
                          <div className="pay_methoed-card-img">
                            <img src={paym1} alt="" />
                          </div>
                          <div className="pay_methoed-card_text-content">
                            <div className="pay_methoed-card-text">
                              Payment Method
                            </div>
                            <div className="pay_methoed-card-ttl">
                              {selectedMethod}
                            </div>
                          </div>
                        </div>
                      </div>
                      {selectedMethod === "UPI" ? (
                        <div className="pay_methoed-card-box">
                          <div className="pay_methoed-card">
                            <div className="pay_methoed-card-img">
                              <img src={paym4} alt="" />
                            </div>
                            <div className="pay_methoed-card_text-content">
                              <div className="pay_methoed-card-text">
                                UPI Handle
                              </div>
                              <div className="pay_methoed-card-ttl">
                                {selectData[0]?.upiHandle}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="pay_methoed-card-box">
                            <div className="pay_methoed-card">
                              <div className="pay_methoed-card-img">
                                <img src={paym4} alt="" />
                              </div>
                              <div className="pay_methoed-card_text-content">
                                <div className="pay_methoed-card-text">
                                  Bank IFSC
                                </div>
                                <div className="pay_methoed-card-ttl">
                                  {selectData[0]?.ifsc}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="pay_methoed-card-box">
                            <div className="pay_methoed-card">
                              <div className="pay_methoed-card-img">
                                <img src={paym2} alt="" />
                              </div>
                              <div className="pay_methoed-card_text-content">
                                <div className="pay_methoed-card-text">
                                  Account Number
                                </div>
                                <div className="pay_methoed-card-ttl">
                                  {selectData[0]?.accountNumber}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="pay_methoed-card-box">
                        <div className="pay_methoed-card">
                          <div className="pay_methoed-card-img">
                            <img src={paym3} alt="" />
                          </div>
                          <div className="pay_methoed-card_text-content">
                            <div className="pay_methoed-card-text">
                              Beneficiary Name
                            </div>
                            <div className="pay_methoed-card-ttl">
                              {selectData[0]?.name}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="payment_detail">
                      <div className="payment_detail-ttl">
                        Enter Payment Details
                      </div>
                      <form onSubmit={formikPayoutFrom.handleSubmit}>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="input-box">
                              <div className="input-label">
                                Enter Amount<span>*</span>
                              </div>
                              <div className="input_field">
                                <input
                                  id="amount"
                                  type="text"
                                  name="amount"
                                  className="input"
                                  placeholder="Enter Amount Here"
                                  value={formikPayoutFrom.values.amount}
                                  onChange={formikPayoutFrom.handleChange}
                                  onBlur={formikPayoutFrom.handleBlur}
                                />
                              </div>

                              {formikPayoutFrom.touched.amount &&
                              formikPayoutFrom.errors.amount ? (
                                <div style={{ color: "red" }}>
                                  {formikPayoutFrom.errors.amount}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="input-box">
                              <div className="input-label">
                                Remarks<span>*</span>
                              </div>
                              <div className="input_field">
                                <input
                                  id="remarks"
                                  type="text"
                                  className="input"
                                  name="remark"
                                  placeholder="Purpose"
                                  value={formikPayoutFrom.values.remark}
                                  onChange={formikPayoutFrom.handleChange}
                                  onBlur={formikPayoutFrom.handleBlur}
                                />
                              </div>
                              {formikPayoutFrom.touched.remark &&
                              formikPayoutFrom.errors.remark ? (
                                <div style={{ color: "red" }}>
                                  {formikPayoutFrom.errors.remark}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </form>

                      <div className="current_balance">
                        Current Balance: <span> Rs. {virtualBalance}</span>
                      </div>
                      {!isHidden && (
                        <div className="imp_note">
                          <div className="d-flex justify-content-between">
                            <div className="note_ttl">An important note!</div>
                            <div onClick={closeCrossIcon}>
                              <img src={crossIcon} alt="" />
                            </div>
                          </div>

                          <div className="note_text">
                            Due to RBI’s guidelines, you can transact maximum of{" "}
                            <span>Rs. 2 Lacs.</span>
                          </div>
                          <div className="note_check">
                            <input
                              type="checkbox"
                              id="note_checkbox"
                              onChange={handleCheckboxChange}
                            />
                            <label htmlFor="note_checkbox">
                              Don’t show me again
                            </label>
                            <button
                              className="btn_dismiss"
                              onClick={dismissCross}
                            >
                              Dismiss
                            </button>
                          </div>
                          <div className="note_close-btn">
                            <img src={closeCrossIcon} alt="" />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="comfirm_payout">
                    <div className="payment_methoed comfirm_pay_methoed">
                      <div className="pay_methoed-card-box">
                        <div className="pay_methoed-card">
                          <div className="pay_methoed-card-img">
                            <img src={paym1} alt="" />
                          </div>
                          <div className="pay_methoed-card_text-content">
                            <div className="pay_methoed-card-text">
                              Payment Method
                            </div>
                            <div className="pay_methoed-card-ttl">
                              {selectedMethod}
                            </div>
                          </div>
                        </div>
                      </div>
                      {selectedMethod === "UPI" ? (
                        <div className="pay_methoed-card-box">
                          <div className="pay_methoed-card">
                            <div className="pay_methoed-card-img">
                              <img src={paym4} alt="" />
                            </div>
                            <div className="pay_methoed-card_text-content">
                              <div className="pay_methoed-card-text">
                                UPI Handle
                              </div>
                              <div className="pay_methoed-card-ttl">
                                {selectData[0]?.upiHandle}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="pay_methoed-card-box">
                            <div className="pay_methoed-card">
                              <div className="pay_methoed-card-img">
                                <img src={paym4} alt="" />
                              </div>
                              <div className="pay_methoed-card_text-content">
                                <div className="pay_methoed-card-text">
                                  Bank IFSC
                                </div>
                                <div className="pay_methoed-card-ttl">
                                  {selectData[0]?.ifsc}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="pay_methoed-card-box">
                            <div className="pay_methoed-card">
                              <div className="pay_methoed-card-img">
                                <img src={paym2} alt="" />
                              </div>
                              <div className="pay_methoed-card_text-content">
                                <div className="pay_methoed-card-text">
                                  Account Number
                                </div>
                                <div className="pay_methoed-card-ttl">
                                  {selectData[0]?.accountNumber}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="pay_methoed-card-box">
                        <div className="pay_methoed-card">
                          <div className="pay_methoed-card-img">
                            <img src={paym3} alt="" />
                          </div>
                          <div className="pay_methoed-card_text-content">
                            <div className="pay_methoed-card-text">
                              Beneficiary Name
                            </div>
                            <div className="pay_methoed-card-ttl">
                              {selectData[0]?.name}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pay_methoed-card-box">
                        <div className="pay_methoed-card">
                          <div className="pay_methoed-card-img">
                            <img src={currenyRupees} alt="" />
                          </div>
                          <div className="pay_methoed-card_text-content">
                            <div className="pay_methoed-card-text">Amount</div>
                            <div className="pay_methoed-card-ttl">
                              {formikPayoutFrom.values.amount}/-
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pay_methoed-card-box">
                        <div className="pay_methoed-card">
                          <div className="pay_methoed-card-img">
                            <img src={anotationDot} alt="" />
                          </div>
                          <div className="pay_methoed-card_text-content">
                            <div className="pay_methoed-card-text">Remarks</div>
                            <div className="pay_methoed-card-ttl">
                              {formikPayoutFrom.values.remark}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="payment_detail">
                      <div className="payment_detail-ttl">Confirm Payout</div>
                      <div className="input-box">
                        <div className="input-label">
                          Enter OTP* <span>*</span>
                        </div>
                        <div className="otp_box payment_otp">
                          <OtpInput onOtpChange={handleOtpChange} />
                        </div>
                      </div>
                      <div className="send_otp">
                    
                        <div className="send_succ">
                          <img src={closeRed} alt="" />
                          Invalid OTP entered!
                        </div>
                        <div className="notRecieve">
                          Didn’t Receive? <a href="#"> Resend </a>(3 attempts
                          left)
                        </div>
                      </div>
                    </div> */}
                  </div>
                </>
              )}
              <Box sx={{ display: "flex", flexDirection: "row", pt: 1 }}>
                <Button
                  variant="outlined" // Outline style
                  startIcon={
                    activeStep === 0 ? <CloseIcon /> : <ArrowBackIcon />
                  }
                  // disabled={activeStep === 0}
                  onClick={activeStep === 0 ? handleClosePayOut : handleBack}
                  sx={{
                    mr: 1,
                    width: "50%",
                    padding: "10px 20px",
                    fontWeight: "600",
                  }}
                  style={{ fontWeight: "700" }}
                >
                  {activeStep === 0 ? "Close" : "Back"}
                </Button>

                <Box sx={{ flex: "1 1 auto" }} />
                <Button
                  style={{ fontWeight: "700" }}
                  onClick={handleNext}
                  variant="contained"
                  endIcon={
                    isLoading ? (
                      <CircularProgress size={20} />
                    ) : activeStep === steps.length - 1 ? (
                      <CheckIcon />
                    ) : (
                      <ArrowForwardIcon />
                    )
                  }
                  sx={{
                    width: "50%",
                    padding: "10px 20px",
                    fontWeight: "600",
                  }}
                  disabled={
                    isLoading ||
                    (activeStep == 0
                      ? isFormValid()
                      : activeStep == 1
                      ? !formikPayoutFrom.isValid || !formikPayoutFrom.dirty
                      : otp.length === 5 && otp.trim() !== ""
                      ? false
                      : false)
                  }
                >
                  {activeStep < steps.length - 1 ? " Next" : "Complete Payout"}
                </Button>
              </Box>
            </React.Fragment>
            {/* )} */}
          </>
        }
      />
    </div>
  );
};

export const Image = ({ image }) => {
  return <img src={image} alt="" />;
};
