import "../styles/style.css";
import "../styles/payout.css";
import fstacLogo from "../assets/images/fstag-logo.svg";
import { SlickSlider } from "../pages/slider";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { login } from "../action/auth";
import { Buffer } from "buffer";
import { useNavigate } from "react-router-dom";
import { AUTH_USER } from "../action/types";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";
import { SingleErrorMessage } from "./singleErrorMessage";

export function Login() {
  const isAuthenticated = useSelector((state) => state.auth.authenticated);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userMid = localStorage.getItem("fstacMid");
  const userSecret = localStorage.getItem("fstacSecretKey");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (userMid && userSecret) {
      navigate("/");
    } else {
      navigate("/login");
    }
  }, []);

  const validationSchema = Yup.object({
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email address"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters long"),
  });
  const loginForm = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      // Step 2: Convert the JSON string to a Buffer
      const buffer = Buffer.from(JSON.stringify(values), "utf-8");
      const payload = {
        data: buffer.toString("base64"),
      };

      const response = await dispatch(login(payload));
      if (response.status === 1) {
        localStorage.setItem("fstacSecretKey", response.data?.secretKey);
        localStorage.setItem("fstacMid", response.data?.mid);
        localStorage.setItem(
          "fStacUserData",
          JSON.stringify({
            email: response.data?.email,
            name: response.data?.name,
            pic: response.data?.pic,
          })
        );

        // toast.success("Login SuccessFully");

        toast.custom(
          <SingleErrorMessage
            data={"Login SuccessFully"}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );

        navigate("/");
        setIsLoading(false);
      } else {
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );

        navigate("/login");
        setIsLoading(false);
      }
    },
  });

  const onCloseToaste = () => {
        toast.dismiss();
  };

  return (
    <div className="login_page">
      <div className="container-1440">
        <div className="login_content">
          <div className="login_left">
            <div className="login_left-content">
              <div className="slider-box">
                <SlickSlider />
              </div>
            </div>
          </div>
          <div className="login_right">
            <div className="login_right-content">
              <div className="login_logo-box">
                <img src={fstacLogo} alt="" className="login_logo" />
              </div>
              <form onSubmit={loginForm.handleSubmit}>
                <div className="login_inner-content">
                  <div className="login">Login</div>
                  <div className="login_desc">
                    Your payout are just a click away
                  </div>
                  <div className="input-box">
                    <div className="input-label">
                      Email ID<span>*</span>
                    </div>
                    <div className="input_field">
                      <input
                        type="email"
                        className="input"
                        id="email"
                        placeholder="Enter your registered email"
                        value={loginForm.values.email}
                        onChange={loginForm.handleChange}
                      />
                    </div>
                    {loginForm.touched.email && loginForm.errors.email && (
                      <div className="danger">{loginForm.errors.email}</div>
                    )}
                  </div>
                  <div className="input-box">
                    <div className="input-label">
                      Enter Password<span>*</span>
                    </div>
                    <div className="input_field">
                      <input
                        type="password"
                        className="input"
                        placeholder="Enter your password"
                        value={loginForm.values.password}
                        onChange={loginForm.handleChange}
                        id="password"
                      />
                    </div>
                    {loginForm.touched.password &&
                      loginForm.errors.password && (
                        <div className="danger">
                          {loginForm.errors.password}
                        </div>
                      )}
                  </div>
                  <div className="mt-5">
                    <button
                      className="btn w-100 primary"
                      style={{
                        border: "1px solid grey",
                        borderRadius: "0.8rem",
                        padding: "0.5rem",
                        position: "relative",
                        zIndex: "1",
                      }}
                      disabled={
                        !loginForm.isValid || !loginForm.dirty || isLoading
                      }
                      type="submit"
                    >
                      Submit{" "}
                      <span>
                        {isLoading ? (
                          <CircularProgress
                            size={18}
                            style={{
                              color: "#e5dada",
                              position: "absolute",
                              top: "11px",
                              left: "63%",
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="sign_up-boxL">
                    {/* <div className="sign_up-line">
                      Don’t have an account? <Link to="/signup">Sign up</Link>
                    </div> */}
                  </div>
                </div>
              </form>

              <div className="login_right-footer">
                <div className="login_footer-left">©Fstac 2024</div>
                <div className="login_footer-right">
                  <img
                    src="/assets/images/help-msg.svg"
                    alt=""
                    className="help-msg"
                  />{" "}
                  help@ftac.com
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
