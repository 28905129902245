import React from 'react';
import { Toaster } from 'react-hot-toast';

export  const ToasterProvider = ({ children }) => {
    return (
        <div>
            <Toaster
                position="top-right" // Set the position of the toaster
                reverseOrder={false} // Display the latest toast first
            />
            {children}
        </div>
    );
};


